import {
  create,
  NConfigProvider,
  NInput,
  NButton,
  NInputGroup,
  NCarousel,
  NIcon,
  NProgress,
  NBreadcrumb,
  NBreadcrumbItem,
  NTag,
  NPagination,
  NDropdown,
  NGrid,
  NGi,
  NForm,
  NFormItem,
  NDatePicker,
  NCascader,
  NSelect,
  NTabs,
  NTabPane,
  NMessageProvider,
  NCard,
  NFormItemRow,
  NTable,
  NUpload,
  NFormItemGi,
  NPopover,
  NModal,
  NSpin,
  NEmpty,
} from 'naive-ui';
// 通用字体
import 'vfonts/Lato.css';
// 等宽字体
import 'vfonts/FiraCode.css';

const naive = create({
  components: [
    NConfigProvider,
    NInput,
    NButton,
    NInputGroup,
    NCarousel,
    NIcon,
    NProgress,
    NBreadcrumb,
    NBreadcrumbItem,
    NTag,
    NPagination,
    NDropdown,
    NGrid,
    NGi,
    NForm,
    NFormItem,
    NDatePicker,
    NCascader,
    NSelect,
    NTabs,
    NTabPane,
    NMessageProvider,
    NCard,
    NFormItemRow,
    NTable,
    NUpload,
    NFormItemGi,
    NSelect,
    NPopover,
    NModal,
    NSpin,
    NEmpty,
  ],
});

export function setupNaive(app) {
  app.use(naive);
}
