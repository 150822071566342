<template>
  <n-form
    :model="formModal"
    ref="formRef"
    :rules="formModalRules"
    label-width="80px"
    require-mark-placement="left"
    label-placement="left"
    size="large"
    class="mt-1"
  >
    <n-form-item label="用户名" path="account" ref="phoneRef">
      <n-input placeholder="请输入用户名" v-model:value="formModal.account" />
    </n-form-item>
    <n-form-item label="手机号" path="phone" ref="phoneRef" first>
      <n-input placeholder="请输入手机号" v-model:value="formModal.phone" />
    </n-form-item>
    <n-form-item label="验证码" path="verificationCode">
      <n-input-group>
        <n-input
          v-model:value="formModal.verificationCode"
          size="large"
          placeholder="请输入验证码"
        />
        <n-button
          size="large"
          :disabled="verifyTime > 0"
          type="primary"
          :loading="codeLoading"
          style="width: 115px !important"
          @click="sendCode"
        >
          {{ verifyTime === 0 ? '发送验证码' : verifyTime + '秒后重试' }}
        </n-button>
      </n-input-group>
    </n-form-item>
    <n-form-item path="password" label="新密码">
      <n-input v-model:value="formModal.password" type="password" @keydown.enter.prevent />
    </n-form-item>
    <n-form-item first path="password2" label="确认密码">
      <n-input v-model:value="formModal.password2" type="password" @keydown.enter.prevent />
    </n-form-item>
    <n-form-item label="身份证号" path="identityNumber">
      <n-input placeholder="请输入身份证号" v-model:value="formModal.identityNumber" />
    </n-form-item>
    <n-form-item label="真实姓名" path="realName">
      <n-input placeholder="请输入真实姓名" v-model:value="formModal.realName" />
    </n-form-item>
    <n-form-item label="邀请码" path="invitationCode">
      <n-input placeholder="请输入邀请码" v-model:value="formModal.invitationCode" />
    </n-form-item>
    <div class="flex justify-end">
      <n-button :loading="saveLoading" class="px-10" type="primary" @click="submit">
        注 册
      </n-button>
    </div>
  </n-form>
</template>

<script setup>
import { reactive, ref, defineEmits } from 'vue';
import { registerApi, sendCodeApi, validate } from '@/api';

const emit = defineEmits(['close']);

const formModalRules = {
  account: [
    {
      required: true,
      trigger: ['input', 'blur'],
      message: '请输入用户名',
      whitespace: true,
    },
    {
      pattern: /^[a-zA-Z0-9]{4,16}$/,
      message: '用户名只能为字母或者数字（长度4~16）',
      trigger: ['input', 'blur'],
    },
  ],
  phone: [
    {
      required: true,
      trigger: ['input', 'blur'],
      message: '请输入手机号',
      whitespace: true,
    },
    {
      pattern: /^1[3456789]\d{9}$/,
      message: '手机格式输入错误，请重新输入',
      trigger: ['input', 'blur'],
    },
  ],
  verificationCode: {
    required: true,
    trigger: ['input', 'blur'],
    message: '请输入验证码',
    whitespace: true,
  },
  password: [
    {
      required: true,
      whitespace: true,
      trigger: ['input', 'blur'],
      message: '请输入密码',
    },
    {
      trigger: ['input', 'blur'],
      min: 6,
      max: 20,
      message: '密码长度为6~20个字符',
    },
  ],
  password2: [
    {
      required: true,
      whitespace: true,
      trigger: ['input', 'blur'],
      message: '请再次输入密码',
    },
    {
      trigger: ['input', 'blur'],
      min: 6,
      max: 20,
      message: '密码长度为6~20个字符',
    },
    {
      validator: validatePasswordStartWith,
      message: '两次密码输入不一致',
      trigger: ['input', 'blur'],
    },
  ],
  identityNumber: [
    {
      required: true,
      trigger: ['input', 'blur'],
      message: '请输入身份证号',
      whitespace: true,
    },
    {
      pattern:
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      message: '身份证号格式输入错误，请重新输入',
      trigger: ['input', 'blur'],
    },
  ],
  realName: {
    required: true,
    trigger: ['input', 'blur'],
    message: '请输入真实姓名',
    whitespace: true,
  },
};

function validatePasswordStartWith(rule, value) {
  return (
    formModal.password &&
    formModal.password.startsWith(value) &&
    formModal.password.length >= value.length
  );
}

const codeLoading = ref(false);
const saveLoading = ref(false);
const verifyTime = ref(0);
const phoneRef = ref(null);
const formRef = ref(null);
const formModal = reactive({
  account: '',
  phone: '',
  verificationCode: '',
  password: '',
  password2: '',
  identityNumber: '',
  realName: '',
  invitationCode: '',
});

function sendCode() {
  phoneRef.value.validate({
    trigger: 'blur',
    async callback(errors) {
      console.log(errors);
      if (errors) {
        return;
      }
      codeLoading.value = true;
      try {
        const res = await sendCodeApi(formModal.phone);
        codeLoading.value = false;
        if (res.success) {
          window.message.success('发送成功');
          // 发送验证码
          verifyTime.value = 60;
          let verifyTimer = setInterval(() => {
            if (verifyTime.value === 0) {
              clearInterval(verifyTimer);
              return;
            }
            verifyTime.value--;
          }, 1000);
        } else {
          throw new Error('发送失败');
        }
      } catch (err) {
        window.message.error(err || '修改失败');
        codeLoading.value = false;
      }
    },
  });
}

function yanzheng() {
  const row = 'idCardNo=' + formModal.identityNumber + '&name=' + formModal.realName;
  console.log(row);
  validate(row).then(res => {
    if (res.code !== undefined && res.data.desc === '一致') {
      console.log(res);
      fn1();
    } else if ((res.code === 200 && res.data.desc === '无记录') || res.data.desc === '不一致') {
      console.log(res);
      window.message.error('身份证和姓名不一致');
    } else {
      console.log(res);
      window.message.error(res.data.msg);
    }
  });
}

async function fn1() {
  saveLoading.value = true;
  try {
    const res = await registerApi(formModal);
    if (res.success) {
      if (res.msg === 'SUCCESS') {
        window.message.success('注册成功 请下载APP进行扫码进行登录', { duration: 3000 });
        emit('close');
      } else {
        window.message.error(res.msg);
      }
    } else {
      throw new Error(res.msg);
    }
  } catch (err) {
    window.message.error(err || '注册失败');
  }
  saveLoading.value = false;
}

function submit(e) {
  e.preventDefault();
  formRef.value.validate(async errors => {
    if (errors) {
      return;
    }
    yanzheng();
    // saveLoading.value = true;
    // try {
    //   const res = await registerApi(formModal);
    //   if (res.success) {
    //     if (res.msg === 'SUCCESS') {
    //       window.message.success('注册成功 请下载APP进行扫码进行登录', { duration: 3000 });
    //       emit('close');
    //     } else {
    //       window.message.error(res.msg);
    //     }
    //   } else {
    //     throw new Error(res.msg);
    //   }
    // } catch (err) {
    //   window.message.error(err || '注册失败');
    // }
    // saveLoading.value = false;
  });
}
</script>
