import Cookies from 'js-cookie';

const TokenKey = 'QJCK-ADMIN';
const cydsTokenKey = 'CYDS-ADMIN';

let exp = new Date();
exp.setTime(exp.getTime() + 60 * 1000); // 过期时间 1分钟

export function getToken () {
  return Cookies.get(TokenKey);
}
export function gettype (token, type) {
  return Cookies.get('ytype');
  // return Cookies.set(TokenKey, token);
}
export function setToken (token, type) {
  Cookies.set('ytype', type, { expires: 1 });
  return Cookies.set(TokenKey, token, { expires: 1 });
  // return Cookies.set(TokenKey, token);
}

export function removeToken () {
  return Cookies.remove(TokenKey);
}
export function getCydsToken () {
  return Cookies.get(cydsTokenKey);
}

export function setCydsToken (token) {
  return Cookies.set(cydsTokenKey, token, { expires: 1 });
  // return Cookies.set(TokenKey, token);
}

export function removeCydsToken () {
  return Cookies.remove(cydsTokenKey);
}
