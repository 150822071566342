<template>
  <div class="footer ">
    <div class="app-container flex" style="margin:0 auto; align-items: center;width: 1109px;">
      <div class="footer-logo flex">
        <img src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/footer_logo.png" alt="" class="footer-logo1 block" />

      </div>
      <!-- <div>
        山东人才信总科技有限公司
      </div> -->
      <div class="shu"></div>
      <div style="flex:1" class="flex">
        <div style="flex:1">
          <!-- <p>咨询/投诉/建议 : 0531－81182679</p> -->
          <!-- <p>咨询/投诉/建议 : sdbyspx@163.com; </p> -->
          <p>公司地址：{{datas.companyAddr||'济南市历城区华龙路1825号嘉恒商务大厦Ｂ座2601'}}</p>
          <p>咨询电话：{{datas.companyTel||'0531-81182679'}} 邮箱：{{datas.companyEmail||'SDBYSPX@163.com'}}</p>
          <p>举报电话：{{'0531-88900415'}} </p>
          <p>
            <a target="_blank"
              href="https://beian.miit.gov.cn/#/Integrated/index">{{datas.companyIcp||'鲁ICP备14024755号'}}
              {{datas.copyright||'山东人才信总科技有限公司'}} 版权所有</a>
          </p>
        </div>
        <div style="fon-size:18px;text-align: center;">
          <div class="flex">
            <img class="w73" style="margin:0 auto"
              src="https://qjck.oss-cn-shanghai.aliyuncs.com/handupload/appdown/zhrc.png" alt="" />
          </div>
          <p>app下载</p>
        </div>
      </div>
    </div>

    <!-- 鲁ICP备14024755号 山东人才信总科技有限公司 版权所有 -->
  </div>
</template>
<script setup>
import { getFooter } from '@/api';
import {
  ref,
} from 'vue';
const datas = ref({})
getFooter().then(res => {
  console.log(res, '独步文中子')
  datas.value = res.data
})
</script>

<style lang="scss">
.footer {
  background: rgb(34, 35, 37);
  line-height: 50px;
  color: rgb(167, 167, 167);
  // text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  height: 260px;
  line-height: 36px;
}
.shu {
  width: 2px;
  height: 76px;
  background-color: #aca9a9;
  margin-right: 30px;
}
.footer-logo {
  width: 380px;
  height: 66px;
  font-size: 20px;
  // margin-right: 150px;
  // margin-top: 30px;
}
.footer-logo1 {
  width: 276px;
  height: 42px;
  margin-right: 25px;
  // margin: 20px auto 0;
}
.footer-logo2 {
  // width: 198px;
  // margin: 20px auto 0;
}
.w73 {
  width: 73px;
}
</style>
