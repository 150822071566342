<template>
  <div>
    <Header />
  </div>
  <div class="df-bg" style="margin-bottom:30px;" v-if="(!lunshow)||(!swiperLoading && carouselList.length === 0)">
    <Nav class="app-container app-container_warp" style="margin:0 auto;z-index:1;" />
  </div>
  <div v-if="lunshow" class="" style="position: relative;">
    <div style="margin:0 auto;" class="df-bg  df-bg-nspin" v-if="!(!swiperLoading && carouselList.length === 0)">
      <Nav class="app-container  df-bg-nspin-in" style="margin:0 auto" />
    </div>
    <NEmpty class="mt-10" description="暂无数据" v-if="!swiperLoading && carouselList.length === 0" />
    <!--      轮播图 开始-->
    <div v-else>
      <NSpin :show="swiperLoading" size="large" :show-dots="false">
        <NCarousel autoplay :show-dots="false">
          <a class="bloc carousel-ima" :href="item.imgLink||'javascript:;'" :target="item.imgLink?'_blank':''"
            v-for="(item, index) in carouselList" :key="index">
            <img class="carousel-img" :src="item.imgUrl" />
          </a>
        </NCarousel>
      </NSpin>
      <!-- <a class="bloc carousel-ima" :href="item.imgLink||'javascript:;'" :target="item.imgLink?'_blank':''"
        v-for="(item, index) in carouselList" :key="index">
        <img class="carousel-img" :src="item.imgUrl" />
      </a> -->
    </div>

  </div>

  <slot></slot>
  <Footer v-if="props.footer" class="mt-8" />
</template>

<script setup>
import {
  defineProps, computed,
  ref,
  shallowRef,
  onMounted,
  onBeforeUpdate,
  reactive,
  watch,
  nextTick,
} from 'vue';
import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import { getHomeBannerApi, getHomeNoticeApi, getClassListApi, getUserInfoApi } from '@/api';
import { useRouter, useRoute } from "vue-router";
let carouselList = shallowRef([]);
const swiperLoading = ref(true);
getHomeBannerApi({ site: 2 })
  .then(res => res.data)
  .then(res => {
    carouselList.value = res;
  })
  .finally(() => (swiperLoading.value = false));
const router = useRouter();
const uid = router.currentRoute.value.name;
const route = useRoute();
const lunshow = ref(true)
console.log(uid, 'uid')

if (uid == "OnlineDetail" || uid == 'Online' || uid == 'news') {
  lunshow.value = false
}
watch(() => route.path, (val, preVal) => {
  // console.log(route, 'route')
  if (router.currentRoute.value.name == 'Home') {
    lunshow.value = true
  } else {
    lunshow.value = false
  }
})
if (uid == 'Home') {
  lunshow.value = true
} else {
  lunshow.value = false
}
const props = defineProps({
  footer: {
    type: Boolean,
  },
});
</script>
<style >
.n-carousel__dots {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.df-bg-nspin {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 10;
  // height: 101px;
  // box-shadow: 0px 28px 41px 14px rgba(188, 188, 188, 0.53);
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.11);
}
.df-bg-nspin-in {
}
.banner {
  width: 100%;
  height: 421px;
  background-image: url('https://qjck.oss-cn-shanghai.aliyuncs.com/xiangyun/gw/banner1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.carousel-img {
  width: 100%;
  height: 100%;
  // object-fit: cover;
  display: block;
}

:deep(.n-carousel) {
  .n-carousel__dots {
    bottom: 35px;
  }

  .n-carousel__dot {
    background-color: #afafaf !important;
    width: 12px;
    height: 12px;
  }

  .n-carousel__dot--active {
    background-color: #df1a23 !important;
  }
}

.step {
  height: 180px;

  .bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .step-item {
    width: 114px;
    height: 114px;
    background-color: #fff;
    border-radius: 50%;

    & + .step-item {
      margin-left: 20px;
    }
  }
}

.understand {
  background-color: #f5f6f9;
}

.plate-header {
  padding-top: 50px;
  padding-bottom: 35px;
}

.lt {
  width: 126px;
  font-size: 30px;
  padding-bottom: 5px;
  border-bottom: 3px solid #df1a23;
  position: relative;
  margin: 0 auto;
  &:after {
    content: '';
    position: absolute;
    left: -87px;
    bottom: -2px;
    width: 300px;
    height: 1px;
    background-color: #acacac;
    z-index: -1;
  }
}
.carousel-ima {
  width: 100%;
  height: 100%;
  display: block;
}
</style>

