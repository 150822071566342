import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setupNaive } from '@/plugins';
import '@/assets/styles/tailwind.css';

import ElementPlus from 'element-plus';
import * as  ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/theme-chalk/index.css';

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
setupNaive(app);

app.use(store).use(router).use(ElementPlus, {
  locale: zhCn,
}).mount('#app');

import '@/api';
