import { createStore } from 'vuex';
import { getToken, setToken, removeToken, getCydsToken, setCydsToken, removeCydsToken, gettype } from '@/utils/auth';
import router from '@/router';

export default createStore({
  state: {
    token: getToken(),
    cydstoken: getCydsToken(),
    stuinfo: {},
    search: '', // 搜索条件
    type: gettype(),
  },
  actions: {
    logout ({ commit }) {
      commit('SET_TOKEN', null);
      router.replace('/');
    },
    logoutcyds ({ commit }) {
      commit('SET_CYDS_TOKEN', null);
      router.replace('/');
    },
  },
  mutations: {
    SET_TOKEN (state, token) {
      if (token) {
        if (token && token.token) {
          setToken(token.token, token.type);
          state.token = token.token;
          state.type = token.type;
        } else {
          setToken(token);
          state.token = token;
        }
      } else {
        removeToken();
        state.token = null;
      }
    },
    SET_CYDS_TOKEN (state, token) {
      if (token) {
        setCydsToken(token);
        state.cydstoken = token;
      } else {
        removeCydsToken();
        state.cydstoken = null;
      }
    },
    SET_STUINFO (state, token) {
      if (token) {
        state.stuinfo = token;
      } else {
        state.stuinfo = {};
      }
    },
  },
});
