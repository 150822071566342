import axios from 'axios';
import router from '@/router';
import { getToken, gettype } from './auth';
import { useStore } from 'vuex';
import store from '@/store/';
// const store = useStore();
function setToken (config) {
  const token = getToken();
  config.headers['Authorization'] = 'Basic ' + 'c2FiZXI6c2FiZXJfc2VjcmV0';
  config.headers['APP-NAME'] = 'ZHRC';
  if (config.url === '/yan/idcard/validate') {
    config.headers['Authorization'] = 'APPCODE ' + 'c092edc44f274925a1d208c77a3d2a70';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // console.log('$$$$$$$$$$$$$$$');
    // console.log(config);
    return
  }
  if (token) {
    // console.log(token)
    config.headers['blade-auth'] = token;
    if (gettype() == 'zj') {
      config.headers['blade-auth'] = "bearer " + token;
    }
    if (gettype() == 'zj' && config.url == '/api/blade-app/mine/getStuInfo') {
      config.url = '/api/blade-policy/roadshow/appGetExpert'
      config.headers['blade-auth'] = "bearer " + token;
    }
    if (gettype() == 'zj' && config.url == '/api/blade-policy/roadshow/appGetExpert') {
      config.headers['blade-auth'] = "bearer " + token;
    }
  } else {

    if (config.url === '/api/blade-auth/token') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

    }
  }
}

const service = axios.create({
  timeout: 1000 * 60 * 5,
  withCredentials: true,
  validateStatus (status) {
    return status >= 200 && status <= 500;
  },
  baseURL: process.env.NODE_EVN === 'production' ? process.env.VUE_APP_REQUEST_BASE_URL : '/',
});

service.interceptors.request.use(
  config => {
    setToken(config);
    return config;
  },
  error => Promise.reject(error),
);

service.interceptors.response.use(
  res => {
    // if (res.status !== 200) {
    //   return Promise.reject('请求错误');
    // }
    console.log(res)
    if (res.data.code === 401) {
      console.log('登录超时')
      console.log(store, useStore, '登录超时')

      // return
      // location.reload();
      store.dispatch('logout');
      // window.message.error('登录超时,请重新登录');
      console.log('登录超时2')

      router.push('/');
      setTimeout(() => {
        location.reload();

      }, 1000)
      return router.push('/');
    }
    if (res.data.code && res.data.code !== 200) {
      // window.message.error(res.data.msg);
      // return Promise.reject(res.data.msg);
      return res;
    }

    return res.data;
  },
  error => Promise.reject(error.message),
);

export default service;
