<template>
  <div class="nav app-container_warp flex" style="margin-bottom:20px">
    <!-- <router-link :to="0 || '/cyds'">
      <div class="cyds">
        创业大赛入口
      </div>
    </router-link> -->
    <!--    <div class="top h-full text-center text-white">全部课程分类</div>-->

    <!-- <NDropdown trigger="hover" :options="all" @select="handleSelect">
      <div class="top  text-center text-white cursor-pointer">
        <span style="color:#000;display: inline-block;">全部课程分类</span>
        <n-icon size="16" style="margin-left: 6px;margin-bottom:-2px;" class="transform rotate-90">
          <svg style="color:#000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24">
            <path d="M6.23 20.23L8 22l10-10L8 2L6.23 3.77L14.46 12z" fill="currentColor"></path>
          </svg>
        </n-icon>
      </div>
    </NDropdown> -->

    <div class="flex justify-center w-full" style="margin:0 auto;">
      <div class="nav-item  px-3 cursor-pointer transition-colors w40" v-for="(item, index) in navList" :key="index">
        <!--        首页导航栏 学习中心-->
        <NDropdown trigger="hover" :options="item.menu" v-if="item.menu" @select="menuSelect">
          <div class="flex items-center">
            <span :class="{ 'router-link-active': computedActiveClass(item) }" class="cff">{{
              item.name
            }}</span>
            <n-icon size="14" style="margin-left: 3px" class="transform rotate-90">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                <path d="M6.23 20.23L8 22l10-10L8 2L6.23 3.77L14.46 12z" fill="currentColor"></path>
              </svg>
            </n-icon>
          </div>
        </NDropdown>
        <!--    首页导航栏 学习中心    -->
        <a v-else-if="item.open" :href="item.link" class="cff" target="_blank">{{ item.name }}</a>
        <div v-else-if="item.open2" @click="topaths(item)" class="cff router-link-exact-active"
          :class="item.open2 == pathtype? 'router-link-active ':''">
          {{ item.name }}
        </div>
        <router-link v-else :to="item.link || '/'" class="cff" style="width:100%;height:100%;display:block;">
          {{ item.name }}
        </router-link>

      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref, watch } from 'vue';

const all = [
  {
    label: '职业技能培训',
    key: '/learning-center/online?type=101',
  },
  {
    label: '创业培训',
    key: '/learning-center/online?type=102',
  },
  {
    label: '创业提升培训',
    key: '/learning-center/online?type=103',
  },
  {
    label: '退伍军人职业技能培训',
    key: '/learning-center/online?type=104',
  },
  {
    label: '继续教育',
    key: '/learning-center/online?type=105',
  },
  {
    label: '公共课程',
    key: '/learning-center/online?type=106',
  },
];

const navList = [
  {
    name: '首页',
    link: '/',
  },
  // {
  //   name: '学习中心',
  //   menu: [
  //     {
  //       label: '在线学习',
  //       key: '/learning-center/online',
  //     },
  //     {
  //       label: '线下学习',
  //       key: '/learning-center/offline',
  //     },
  //     {
  //       label: '教材领用',
  //       key: '/learning-center/textbook-collection',
  //     },
  //     {
  //       label: '认证考试',
  //       key: '/learning-center/exams',
  //     },
  //     {
  //       label: '考评评审',
  //       key: '/learning-center/review',
  //     },
  //     {
  //       label: '结业证书',
  //       key: '/learning-center/cer-completion',
  //     },
  //     {
  //       label: '代领协议',
  //       key: '/learning-center/agreement',
  //     },
  //   ],
  // },
  {
    name: '通知公告',
    link: '/news?type=1002',
    open2: 1001,
  },
  {
    name: '工作动态',
    link: '/news?type=1003',
    open2: 1002,
  },
  {
    name: '在线学习',
    link: '/learning-center/online',
  },
  // {
  //   name: '线下学习',
  //   link: '/learning-center/offline',
  // },
  // {
  //   name: '教材领用',
  //   link: '/learning-center/textbook-collection',
  // },
  {
    name: '认证考试',
    link: '/learning-center/exams',
  },
  {
    name: '政策法规',
    link: '/news?type=1004',
    open2: 1003,

  },
  {
    name: '下载中心',
    link: '/news?type=1005',
    open2: 1004,
  },

  // {
  //   name: '考评评审',
  //   link: '/learning-center/review',
  // },
  // {
  //   name: '结业证书',
  //   link: '/learning-center/cer-completion',
  // },
  {
    name: '关于我们',
    link: '/news?type=1005',
    open2: 1005,
  },
  // {
  //   name: '代领协议',
  //   link: '/learning-center/agreement',
  // }
];

const router = useRouter();
const route = useRoute();
const pathtype = ref('');

if (route.path == '/news') {
  pathtype.value = route.query.type
}
watch(() => route.query, (val, preVal) => {
  //val为修改后的值,preVal为修改前的值
  console.log("message", val, preVal)
  if (route.path == '/news') {
    pathtype.value = val.type
  }
})
function menuSelect (key) {
  if (typeof key === 'string') {
    router.push(key);
  }
}
function topaths (item) {
  router.push('/news?type=' + item.open2);

}
// 全部课程 分类
function handleSelect (key) {
  console.log(key);
  router.push(key);
}

function computedActiveClass (menu) {
  if (!menu.menu) {
    return false;
  }
  if (route.fullPath === '/') {
    return false;
  }
  const pathArr = route.fullPath.split('/');
  if (pathArr.length > 1 && pathArr[1]) {
    return menu.menu[0].key.startsWith('/' + pathArr[1]);
  }
  return false;
}
</script>

<style lang="scss">
.router-link-active {
  font-weight: 700;
}
.cff {
  text-align: center;
}
.nav {
  height: 70px;
  line-height: 70px;
  align-items: center;
  font-size: 22px;
  .nav-item:hover {
    color: #ea4c00;
  }
}
.px-3 {
  padding-left: 0 !important;
}
.top {
  background-color: #fff;
  width: 260px;
  color: #000000;
  height: 50px;
  line-height: 50px;
}
.cyds {
  position: fixed;
  right: 30px;
  top: 300px;
  font-size: 30px;
  z-index: 99;
}
.w40 {
  flex: 1;
}
</style>
