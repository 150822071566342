import request from '@/utils/request';
// import { nextTick } from 'vue';
// nextTick(() => window.message.success('哈哈哈哈哈'));

export const loginApi = (params) =>
  request({
    url: '/api/blade-auth/token',
    method: 'post',
    params,
  });


// 首页轮播图
export const getHomeBannerApi = (params) =>
  request({
    url: '/api/blade-app/website/listBanner',
    method: 'get',
    params,
  });


// 单独请求关于我们
export const channelSingleNew = (params) =>
  request({
    url: '/api/blade-app/website/news/channelSingleNew',
    method: 'get',
    params,
  });
// 新闻栏目
export const siteChannel = (params) =>
  request({
    url: '/api/blade-app/website/news/siteChannel',
    method: 'get',
    params,
  });
// 查询栏目的新闻
export const channelNews = (params) =>
  request({
    url: '/api/blade-app/website/news/channelNews',
    method: 'get',
    params,
  });
// 根据id查询新闻
export const newsDetail = (params) =>
  request({
    url: '/api/blade-app/website/news/newsDetail',
    method: 'get',
    params,
  });
// 根据id查询新闻
export const channelNewsSearch = (params) =>
  request({
    url: '/api/blade-app/website/news/channelNewsSearch',
    method: 'get',
    params,
  });

//联系我们
export const saveContact = (data) =>
  request({
    url: '/api/blade-app/website/news/saveContact',
    method: 'post',
    data,
  });
//查询班级信息
export const classdetail = (params) =>
  request({
    url: '/api/blade-policy/class/detail',
    method: 'get',
    params,
  });
// 首页通知消息
export const getHomeNoticeApi = () =>
  request({
    url: '/api/blade-app/website/listNotice',
    method: 'get',
  });

export const scanCodeLoginApi = uuid =>
  request({
    url: '/api/blade-app/website/result/' + uuid,
    method: 'get',
  });

// 首页内容数据
export const getClassListApi = params =>
  request({
    url: '/api/blade-app/website/pageCourse',
    method: 'get',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
    params,
  });
//获取学时明细
export const getStuCredit = params =>
  request({
    url: '/api/blade-policy/class/getStuCredit',
    method: 'get',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
    params,
  });

//课程是否能够播放
export const courseCanPlay = params =>
  request({
    url: '/api/blade-app/website/courseCanPlay',
    method: 'get',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
    params,
  });
// 课程分类枚举数据
export const getClassListClassisDataApi = () =>
  request({
    url: '/api/blade-system/dict/codeTree?code=curriculum_train',
    method: 'get',
  });

// 课程详情
export const getClassDetailApi = id =>
  request({
    url: '/api/blade-app/website/detailDetail?id=' + id,
    method: 'get',
  });

// 课程详情中 视频播放时记录学分
export const recordVideoPlayCreditApi = data =>
  request({
    url: '/api/blade-app/startStudy/saveOrUpdateWatchHistory',
    method: 'post',
    data,
  });

// 服务评价 查询
export const websitePaper = () =>
  request({
    url: '/api/blade-policy/feedback/uni/websitePaper',
    method: 'get',
  });

// 调查问卷
export const websitePaperDiao = params =>
  request({
    url: '/api/blade-policy/feedback/uni/websitePaper',
    method: 'get',
    params,
  });

// 服务评价 保存
export const submitPaper = data =>
  request({
    url: '/api/blade-policy/feedback/uni/submitPaper',
    method: 'post',
    data,
  });

// 获取我的信息
export const getUserInfoApi = () =>
  request({
    url: '/api/blade-app/mine/getStuInfo',
    method: 'get',
  });

// 上传信息补录中的相关证明
export const uploadVerifyPictureApi = () =>
  request({
    url: '/api/blade-app/mine/verifyPicture',
    method: 'get',
  });

// 获取字段数据
export const getKeysApi = params =>
  request({
    url: '/api/blade-app/mine/getKeys?code=' + params,
    method: 'get',
  });

// 级联获取省市区
export const getSelectCityApi = code =>
  request({
    url: '/api/blade-system/region/select?code=' + code,
    method: 'get',
  });

// 保存信息补录
export const saveInformationSupplement = data =>
  request({
    url: '/api/blade-app/mine/informationSupplement',
    method: 'post',
    data,
  });

// 个人信息中-获取个人线上、线下情况
export const getEligibilityStu = params =>
  request({
    url: '/api/blade-policy/class/eligibilityStu',
    method: 'get',
    params,
  });

// 意见反馈
export const saveFeedbackApi = content =>
  request({
    url: '/api/blade-app/website/saveFeedback',
    method: 'post',
    params: {
      content,
    },
  });

// 意见反馈 查询意见反馈列表
export const listFeedback = () =>
  request({
    url: '/api/blade-app/website/listFeedback',
    method: 'get',
  });

// 新增 课程评价
export const saveCourseEvaluate = data =>
  request({
    url: '/api/blade-app/website/saveCourseEvaluate',
    method: 'post',
    data,
  });

// 课程评价 查询
export const pageCourseEvaluate = params =>
  request({
    url: '/api/blade-app/website/pageCourseEvaluate',
    method: 'get',
    params,
  });

// 修改昵称
export const editUsernameApi = name =>
  request({
    url: '/api/blade-app/appUser/updateName?name=' + name,
    method: 'post',
  });

// 修改密码
export const editPasswordApi = params =>
  request({
    url: '/api/blade-app/appUser/updatePassWord',
    method: 'post',
    params,
  });

// 发送验证码
export const sendCodeApi = phoneNumbers =>
  request({
    url: '/api/blade-app/appUser/sendSms?phoneNumbers=' + phoneNumbers,
    method: 'get',
  });

// 修改手机号
export const editPhoneApi = params =>
  request({
    url: '/api/blade-app/appUser/updatePhone',
    method: 'post',
    params,
  });

// 注册
export const registerApi = data =>
  request({
    url: '/api/blade-app/appUser/save',
    method: 'post',
    data,
  });

// 显示教材是否领用
export const teachingMaterialsVerifyApi = () =>
  request({
    url: '/api/blade-app/mine/teachingMaterialsVerify',
    method: 'get',
  });

// 代领协议回显
export const getAgencyAgreementApi = () =>
  request({
    url: '/api/blade-app/mine/getAgencyAgreement',
  });

// 带领协议是否签署
export const verifyAgencyAgreementApi = () =>
  request({
    url: '/api/blade-app/mine/verifyAgencyAgreement',
  });

// 是否已结业
export const verifyCertificateOfConformityApi = () =>
  request({
    url: '/api/blade-app/mine/verifyCertificateOfConformity',
  });

// 结业证书
export const getCertificateOfConformityApi = params =>
  request({
    url: '/api/blade-policy/stucertificate/certificates',
    params,
  });

// 认证考试
export const examIndexInfoApi = () =>
  request({
    url: '/api/blade-app/mine/examIndexInfo',
  });
// 获取底部文字
export const getFooter = () =>
  request({
    url: '/api/blade-app/website/news/getFooter?site=2',
  });

// 考评评审获取进度数据
export const businessPlanStatusApi = () =>
  request({
    url: '/api/blade-app/mine/businessPlanStatus',
  });

// 根据日期获取当前日期所有线

export const getClassInfoByDateApi = () =>
  request({
    url: '/api/blade-app/startStudy/getClassInfoByDate',
  });

// 二要素验证
export const validate = data =>
  request({
    url: '/yan/idcard/validate',
    method: 'post',
    data,
  });

// 缴费二维码
export const ispay = params =>
  request({
    url: '/api/blade-policy/paywechat/ispay',
    method: 'get',
    params,
  });
// 付费课程校验
export const payCourseCheck = params =>
  request({
    url: '/api/blade-app/website/payCourseCheck',
    method: 'get',
    params,
  });
// 上传付费凭证
export const saveCoursePay = data =>
  request({
    url: '/api/blade-app/website/saveCoursePay',
    method: 'post',
    data,
  });

// 发票管理 列表
export const webMyInvoice = params =>
  request({
    url: '/api/blade-policy/invoice/webMyInvoice',
    method: 'get',
    params,
  });

// 发票管理 申请 保存
export const webApply = data =>
  request({
    url: '/api/blade-policy/invoice/webApply',
    method: 'post',
    data,
  });

// 个人学习 报告
export const grdetailList = params =>
  request({
    url: '/api/blade-policy/stuBaseInfo/detailList',
    method: 'get',
    params,
  });

// 登录 轮询
export const lunresult = params =>
  request({
    url: '/api/blade-app/website/result/' + params,
    method: 'get',
  });

// 及时答题
export const instantAnswer = params =>
  request({
    url: '/api/blade-app/startStudy/instantAnswer',
    method: 'get',
    params,
  });

// 查看解析接口
export const instantAnswerAnalyze = params =>
  request({
    url: '/api/blade-app/startStudy/instantAnswerAnalyze',
    method: 'post',
    params,
  });

// 章节测试
export const chapterTest = params =>
  request({
    url: '/api/blade-app/startStudy/chapterTest',
    method: 'get',
    params,
  });

// 线下 学时详情 type='' 线下 type=''线上
export const lessonDetail = params =>
  request({
    url: '/api/blade-policy/class/lessonDetail',
    method: 'get',
    params,
  });
export const lineStu = params =>
  request({
    url: '/api/blade-policy/class/lineStu',
    method: 'get',
    params,
  });
export const liveDetailNew = params =>
  request({
    url: '/api/blade-policy/class/liveDetailNew',
    method: 'get',
    params,
  });

// 考试详情 学员信息
export const getStuInfoScore = params =>
  request({
    url: '/api/blade-policy/stuBaseInfo/getStuInfoScore',
    method: 'get',
    params,
  });

// 考试详情 考试预览
export const stuExamRecordWithPhoto = params =>
  request({
    url: '/api/blade-policy/questionanswer/stuExamRecordWithPhoto',
    method: 'get',
    params,
  });

// 评审详情
export const reviewDetail = params =>
  request({
    url: '/api/blade-center/businessplan/reviewDetail',
    method: 'get',
    params,
  });

// 校验线下课上课中不可以看网络课
export const verifyOnlineClass = () =>
  request({
    url: '/api/blade-app/startStudy/verifyOnlineClass',
    method: 'get',
  });

// 播放地址链接
export const getPlayInfo = videoId =>
  request({
    url: '/api/blade-policy/aliyun/vod/getPlayInfo?videoId=' + videoId,
    method: 'get',
  });
//机构appGetExpert
export const getTenantByUserName = (account, password) => request({
  url: '/api/blade-app/appUser/getUserRole',
  method: 'get',
  params: {
    account,
    password
  }
})
//专家获取我的信息
export const appGetExpert = () => request({
  url: '/api/blade-policy/roadshow/appGetExpert',
  method: 'get',
  params: {
  }
})
//网站端-学员查询速录列表
export const appShorthandList = (params) => request({
  url: '/api/blade-policy/shorthand/appShorthandList',
  method: 'get',
  params,
})
//网站端-学员查询速详情
export const appShorthandDetail = (shorthandId) => request({
  url: '/api/blade-policy/shorthand/appShorthandDetail',
  method: 'get',
  params: {
    shorthandId
  }
})
//网站端-学员查询速录列表
export const appSubmitShorthand = (data) => request({
  url: '/api/blade-policy/shorthand/appSubmitShorthand',
  method: 'post',
  data,
})
//网站端-学员保存速录-模拟考试不保存
export const appSubmitShorthandMock = (data) => request({
  url: '/api/blade-policy/shorthand/appSubmitShorthandMock',
  method: 'post',
  data,
})
//获取班级配置参数
export const getClassParamByKey = (data) => request({
  url: '/api/blade-app/appUser/getClassParamByKey',
  method: 'get',
  params: {
    ...data
  },
})
//课程监管拍照上传对比照片相似度
export const identityphotoNew = (data) => request({
  url: '/api/blade-app/appUser/face/identity/photoNew',
  method: 'get',
  params: {
    ...data
  },
})
//获取看课课程检测信息
export const videoSupervise = (data) => request({
  url: '/api/blade-app/startStudy/videoSupervise',
  method: 'get',
  params: {
    ...data
  },
})
//保存学习人脸记录
export const saveFaceSnapshotapi = (data) => request({
  url: '/api/blade-app/startStudy/saveFaceSnapshot',
  method: 'post',
  data
})
//提交章节测试
export const addTestRecordNewapi = (data) => request({
  url: '/api/blade-app/startStudy/addTestRecordNew',
  method: 'post',
  data
})
//获取机构配置参数request.requestGet(`${url}${formatGetUri(data)}`)
export const getTenantParamByKey = (data) => request({
  url: '/api/blade-app/appUser/getTenantParamByKey',
  method: 'get',
  params: {
    ...data
  },
})

//获取新闻列表
export const pageByCategory = (data) => request({
  url: '/api/blade-desk/notice/pageByCategory',
  method: 'get',
  params: {
    ...data
  },
})
//获取新闻列表
export const saveLog = (data) => request({
  url: '/api/blade-policy/digitalAudit/saveLog',
  method: 'post',
  data
})

//新增人脸审核记录
export const appfaceauditrecordSave = (data) => request({
  url: '/api/blade-app/appfaceauditrecord/save',
  method: 'post',
  data
})

// 校验线下课上课中不可以看网络课(true:可以观看 false:不可观看)

// api.verifyOnlineClass = (data) => request.requestGet(`${url}/blade-app/startStudy/verifyOnlineClass`)