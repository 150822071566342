<template>
  <div>
    <div class="hover:shadow-xl cursor-pointer transition-shadow boxsdo cu" v-if="!intype" @click="gotoDetail">
      <div class="plate-group-item  transition-shadow duration-300">
        <div class="plate-group-item_in"
          :style="'background-image: url('+'https://qjck.oss-cn-shanghai.aliyuncs.com/' + data.profilePicture+')'">
          <!-- <img class="thumbnail" :src="'https://qjck.oss-cn-shanghai.aliyuncs.com/' + data.profilePicture"
        :alt="data.courseName" /> -->
          <div class="poarig" v-if="isLogin">
            {{ (data.credit / 45).toFixed(2) }}课时
          </div>
          <div class="learningProgress" v-if="isLogin">
            {{data.learningProgress}}%
          </div>
          <!-- <div class="flex items-center justify-between mt-1 px-2 plate-group-item_footer">
          <span style="color: #000">总：{{ (data.credit / 45).toFixed(2) }}课时</span>
        
          <span v-if="isLogin">已完成: {{data.learningProgress}}%</span>
          <span v-if="isLogin===false">已完成: 0%</span>
        </div> -->

        </div>

      </div>
      <div :title="data.courseName" class="p-1 text-base  border-gray-200 truncate courseName">
        {{ data.courseName }}
      </div>
    </div>

    <div v-else @click="gotoDetail" class="cu">
      <div class="online_item">
        <img class="online_item_img" :src="'https://qjck.oss-cn-shanghai.aliyuncs.com/' + data.profilePicture"
          :alt="data.courseName" />
        <div class="flex online_bottom" v-if="isLogin">
          <div v-if="isLogin">
            总：{{ (data.credit / 45).toFixed(2) }}课时
          </div>
          <div class="learningProgress2" v-if="isLogin">
            {{data.learningProgress}}%
          </div>
          <!-- <div class="learningProgress2">
            0%
          </div> -->
        </div>
      </div>
      <div :title="data.courseName" class="p-1 text-base  border-gray-200 truncate courseName online_item_courseName">
        {{ data.courseName }}
      </div>
    </div>
    <div>
      <n-modal v-model:show="showModal" :mask-closable="false" :on-after-leave="onModalAfterLeave">
        <n-card style="width: 450px" title="请使用智慧人才APP进行扫码认证" :bordered="false" size="medium">
          <template #header-extra>
            <n-icon size="26" @click="closeQrcode" class="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
                <path
                  d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4l6.6 6.6L8 22.6L9.4 24l6.6-6.6l6.6 6.6l1.4-1.4l-6.6-6.6L24 9.4z"
                  fill="currentColor"></path>
              </svg>
            </n-icon>
          </template>
          <div class="qrcode-img">
            <div id="login-qrcode"></div>
            <div class="qrcode-error" v-if="qrcodeBeoverdue">
              <p>{{ qrcodeMsg }}</p>
              <!--<button class="refresh" @click="refreshQrcode">刷新二维码</button>-->
            </div>
          </div>
        </n-card>
      </n-modal>
    </div>
    <el-dialog v-model="dialogVisible" title="" width="30%">
      <h1 style="font-size:16px;color:red;text-align: center;" v-if="payCourseCheckdata.payStep==8">
        被驳回请重新上传支付凭证
      </h1>
      <h1 style="font-size:16px;color:#67C23A;text-align: center;" v-if="payCourseCheckdata.payStep==1">
        凭证已上传请等待审核通过
      </h1>
      <h1 style="font-size:16px;color:#333;text-align: center;">
        此课程为付费课程，您需要支付
      </h1>
      <h1 style="font-size:16px;color:#333;text-align: center;">

        <span style="font-size:35px;color:red">{{payCourseCheckdata.coursePrice}}</span> 元
      </h1>
      <p style="margin-top:40px;font-size:15px">
        收款账户信息:
      </p>
      <div style="font-size:12px;padding-left:10px;margin-top:10px">
        <!-- <p>
          单位名称 山东人才信总科技有限公司
        </p>
        <p>
          税 号 91370100746556523D
        </p>
        <p>
          开户行及账号 建行济南山大南路支行 37001618805050017216
        </p>
        <p>
          地址、电话 中国（山东）自由贸易试验区济南片区新泺大街1166号奥盛大厦1号楼2333-2室 81182687
        </p> -->
        <p style=" white-space: pre-line;">
          {{payCourseCheckdata.account}}
        </p>
        <div style="font-size:16px;color:#333;text-align: center;margin-top:10px;">
          支付凭证:
        </div>
        <div style="margin-top:10px;" class="ambo">
          <Upload :disabled2='false' @dataChange="dataChange" v-bind:path="formModel.proof" style="margin:0 auto;">
          </Upload>
        </div>
      </div>

      <span>

      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveCoursePays" :disabled='payCourseCheckdata.payStep==1'>
            上传支付凭证
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import Upload from '@/views/user/components/Upload2';
import { client, getObjectUrl, objectToBuffer } from '@/utils/ali-oss';
import { ref, computed, defineProps, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getUserInfoApi, courseCanPlay, payCourseCheck, saveCoursePay } from '@/api/index';
import QRCode from 'qrcodejs2';
import { ElMessage, ElMessageBox } from 'element-plus'
const store = useStore();
const showModal = ref(false)
const isLogin = computed(() => Boolean(store.state.token));
let source = null;
// eslint-disable-next-line vue/no-setup-props-destructure
const { data, wathc_video_face_need, userinfo, intype } = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  userinfo: {
    type: Object,
    default: () => ({}),
  },
  intype: {
    type: String,
    default: () => (''),
  },
  wathc_video_face_need: {
    type: Boolean,
    default: () => (false),
  },
});
function saveCoursePays () {
  if (!formModel.value.proof) {
    message.error('请选择凭证');
    return
  }
  if (payCourseCheckdata.value.payStep == 1) {
    message.error('请等待审核');
    return
  }
  saveCoursePay({
    courseId: data.id,
    payPic: formModel.value.proof
  }).then(res => {
    ElMessage.success('上传成功,请等待审核')
    dialogVisible.value = false

  })
}
// 关闭 二维码弹框
function closeQrcode () {
  showModal.value = false;
  document.querySelector('#login-qrcode').innerHTML = '';
  // clearInterval(timer); // 关闭循环
}
const qrcodeBeoverdue = ref(false);
function renderQrcode (row) {
  qrcodeBeoverdue.value = false;
  const text = {
    id: row.id,
    courseId: data.id,
    type: 'srrz',
  };

  // setTimeout(() => {
  new QRCode('login-qrcode', {
    width: 164,
    height: 164,
    text: JSON.stringify(text),
  });
  connectService(row.id)
  // }, 1000)
}
function onModalAfterLeave () {
  cancelConnect();
}
function cancelConnect () {
  if (source) {
    source.close();
    source = null;
  }
}
let qrcodeMsg = ref('');
function connectService (uuid, courseId) {
  console.log('#########################');
  if (window.EventSource) {
    // 建立连接
    source = new EventSource('/api/blade-app/website/connect/' + uuid);

    /**
     * 连接一旦建立，就会触发open事件
     * 另一种写法：source.onopen = function (event) {}
     */
    source.onopen = function (event) {
      console.log(event);
    };

    /**
     * 客户端收到服务器发来的数据
     * 另一种写法：source.onmessage = function (event) {}
     */
    source.onmessage = function (event) {
      console.log(event);
      let res = event.data;
      if (!res) {
        return;
      }
      if (res === 'STUDY::SSE::PROCESSSTUDY') {
        qrcodeMsg.value = '扫码中';
        qrcodeBeoverdue.value = true;
        return;
      }
      try {
        if (res == 'STUDY::SSE::RESULT::1') {
          qrcodeMsg.value = '认证成功';
          ElMessage.success('认证成功')
          showModal.value = false
          callbacks()
        } else if (res == 'STUDY::SSE::RESULT::0') {
          qrcodeMsg.value = '认证失败';
          console.log(res);
          message.error('认证失败');
        }
      } catch (err) {
        message.error('扫码出现错误，请刷新页面后重试！');
        qrcodeMsg.value = '请刷新后重试';
        qrcodeBeoverdue.value = true;
      }
    };

    /**
     * 如果发生通信错误（比如连接中断），就会触发error事件
     * 或者：
     * 另一种写法：source.onerror = function (event) {}
     */
    source.onerror = function (event) {
      message.error('扫码登录出现异常，请刷新页面后重试！');
      qrcodeMsg.value = '请刷新页面后重试';
      qrcodeBeoverdue.value = true;
      cancelConnect();
    };
  } else {
    console.log('@@@@@');

    // var sum = ref(0);
    timer = setInterval(function () {
      // lunresult(uuid).then(res => {
      //   if (JSON.stringify(res.data) !== '{}' && res.success === true) {
      //     qrcodeBeoverdue.value = true;
      //     console.log(res);
      //     qrcodeMsg.value = '登录成功';
      //     message.success('登录成功');
      //     sessionStorage.setItem('TOUXIANG', '123');
      //     store.commit('SET_TOKEN', res.data.token);
      //     cancelConnect();
      //     window.location.reload(true);
      //     clearInterval(timer); // 关闭循环
      //   }
      // });
    }, 2000);

    //
  }
}
const router = useRouter();
let callbacks = function () {

}
const formModel = ref({ proof: '' })
const dialogVisible = ref(false)
const payCourseCheckdata = ref({})
function dataChange (res) {
  console.log(res)
  formModel.value.proof = res;
}
async function gotoDetail (type) {
  console.log(data)
  if (isLogin.value) {
    // router.push('/learning-center/online-detail/' + data.id);
    if (data.needPay == 'Y' && data.coursePrice > 0 && type != 1) {
      payCourseCheck({ courseId: data.id }).then(res => {
        console.log(res)
        if (res.data.hasPay) {
          gotoDetail(1)
        } else {
          console.log(res.data)
          payCourseCheckdata.value = res.data
          console.log(payCourseCheckdata.value.hasPay)
          dialogVisible.value = true
          formModel.value.proof = res.data.payPic
        }
      })
      return
    }
  } else {
    window.$login()
    return
  }
  console.log(wathc_video_face_need)
  callbacks = function () {
    router.push('/learning-center/online-detail/' + data.id);

  }
  courseCanPlay({ courseId: data.id }).then(res => {
    console.log(res)
    if (res.data) {
      if (wathc_video_face_need) {
        console.log(data)
        showModal.value = true;
        nextTick(() => {
          renderQrcode(userinfo);

        });
        return

      } else {
        callbacks()
      }
    } else {
      ElMessageBox.alert('您无法观看该课程,请进入在线学习页面观看', '提示', {
        confirmButtonText: '确定',
      })
    }
  })
}
</script>

<style lang="scss" scoped>
.plate-group-item {
  width: 356px;
  height: 251px;
  // border: 1px solid #df1a23;
  overflow: hidden;
  box-sizing: border-box;
  // padding: 8px;
  position: relative;
  > .thumbnail {
    height: 120px;
    object-fit: cover;
    margin: 0 auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.plate-group-item_in {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
}
.plate-group-item_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 34px;
  background: #ffffff;
  opacity: 0.67;
  line-height: 34px;
}
.plate-group-item_footer span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  display: block;
  line-height: 34px;
  height: 34px;
}
:deep(.n-progress-text) {
  font-size: 12px !important;
}
.qrcode-img {
  position: relative;
  box-shadow: 0px 0px 1px 1px rgba(28, 28, 32, 0.05), 0px 8px 24px rgba(28, 28, 32, 0.12);
  border-radius: 10px;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  font-size: 14px;
  background-color: #fff;
  overflow: hidden;
  padding: 8px;
}
.ambo {
  display: flex;
  justify-content: center;
}
.qrcode-error {
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  > p {
    color: #222;
    margin-top: 75px;
    margin-bottom: 8px;
    text-align: center;
    font-size: 18px;
  }

  .refresh {
    font-size: 12px;
    color: #f0f0f0;
    background: #1890ff;
    line-height: 24px;
    height: 24px;
    width: auto;
    padding: 0px 10px;
    border-radius: 30px;
    border: 0;
    margin: 0 auto;
    display: block;
  }
}

.qrcode-desc {
  text-align: center;
  font-size: 12px;
  color: #25262b;
}

.personage_1 {
  display: inline-block;
  width: 48px;
  height: 22px;
  img {
    border-radius: 25px;
  }
}
.courseName {
  text-align: center;
  // margin-top: 10px;
  height: 57px;
  line-height: 49px;
  font-size: 18px;
  overflow: hidden;
  width: 356px;
  box-sizing: border-box;
  padding: 0 10px;
}
.online_item_courseName {
  background-color: #2d60f6;
  color: #fff !important;
  margin-top: 0 !important;
  height: 37px !important;
  line-height: 37px !important;
  margin-bottom: 0 !important;
  // padding: 0;
  box-shadow: -10px 4px 44px 1px rgb(133 133 133 / 69%);
}
.poarig {
  position: absolute;
  right: 0;
  top: 32px;
  min-width: 117px;
  height: 41px;
  // background-image: linear-gradient(to right, #235bf3, #5cacd2, #82e782);
  background-color: rgba(60, 111, 239);
  border-radius: 21px 0px 0px 21px;
  line-height: 41px;
  font-size: 22px;
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 10px;
  color: #fff;
}
.boxsdo {
  box-shadow: -10px 4px 44px 1px rgba(133, 133, 133, 0.19);
}
.learningProgress {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.4);
}
.learningProgress2 {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
  // background-color: rgba(0, 0, 0, 0.4);
}
.online_item {
  position: relative;
  width: 263px;
  height: 158px;
  overflow: hidden;
  cursor: pointer;
}
.online_item_img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}
.online_item:hover .online_item_img {
  transform: scale(1.4);
}
.online_bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(111, 111, 111, 0.38);
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 10px;
  align-items: center;
  color: #fff;
}
.online_item_courseName {
  text-align: center;
  width: 263px;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
