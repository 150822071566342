<template>
  <div>
    <el-upload :auto-upload="true" :action="uploadUrl" ref="upload" :on-success="fnUploadSuccess"
      :on-remove="handleRemove" :http-request="fnUploadRequest" :disabled="disabled" :limit="1" list-type="picture-card"
      accept=".jpg, .jpeg, .png, .gif" :show-file-list="false" :multiple="false" v-if="path === ''">
      <el-button size="small" type="primary" v-show="!disabled">点击上传 </el-button>
      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div> -->
    </el-upload>
    <div v-if="path !== ''" class="el-upload-list el-upload-list--picture-card">
      <div class="el-upload-list__item is-success">
        <img class="el-upload-list__item-thumbnail" :src="ossPath" />
        <label class="el-upload-list__item-status-label">
          <i class="el-icon-upload-success el-icon-check"></i>
        </label>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" v-show="!disabled">
            <i class="el-icon-delete" @click="handleRemove()"></i>
          </span>
          <span class="el-upload-list__item-preview">
            <!-- <i class="el-icon-zoom-in" @click="handlePreview()"></i> -->
          </span>
        </span>
      </div>
    </div>

    <!--    <el-dialog v-model:visible="imageDialog" append-to-body>-->
    <!--      &lt;!&ndash;      <img width="100%" :src="ossPath" alt="" />&ndash;&gt;-->
    <!--      1111-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
import { client, getObjectUrl, objectToBuffer } from '../../../utils/ali-oss';
import { compressImage } from '../../../utils/compressImage';
export default {
  props: {
    // 是否展示查看层
    // imageDialog: {
    //   type: Boolean,
    //   default: function () {
    //     return false;
    //   },
    // },
    // 上传图片路径
    disabled2: {
      default: true
    },
    path: {
      type: String,
      default: function () {
        return '';
      },
    },
  },
  data () {
    return {
      data: {},
      uploadUrl: '',
      limit: 10,
      disabled: false,
      empty: '',
      tu: '',
      imageDialog: false,
    };
  },
  computed: {
    // 选择的没有班级的学员ids
    ossPath () {

      return getObjectUrl(this.path);
    },
  },
  created () {
    if (this.path && this.disabled2) {
      this.disabled = true
    }
  },
  methods: {
    handlePreview () {
      console.log('123456');
      this.imageDialog = true;
    },
    getUUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16);
      });
    },
    getNowFormatDate () {
      // 获取当月时间 yyyy-MM-dd
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = '0' + month;
      }
      if (strDate >= 1 && strDate <= 9) {
        strDate = '0' + strDate;
      }
      return year + '-' + month + '-' + strDate;
    },
    handleRemove () {
      console.log(this.path);
      if (this.path !== undefined && this.path !== '') {
        // 删除oss上的照片
        // alert("1")
        // try {
        // client().delete(this.path);
        // } catch (e) {
        // console.log(e);
        // }
        this.data = {};
        // this.path = '';
        this.tu = '';
        console.log('shanchu');
        console.log(this.tu);
        // this.$emit("dataChange", this.path, this.imageType, this.data);
        this.$emit('dataChange', this.tu, this.imageType, this.data);
      }
    },
    // 自定义实现上传方式
    fnUploadRequest (option) {
      console.log(option);
      try {
        let file = option.file;
        let date = this.getNowFormatDate();
        let fileNames = this.getUUID() + file.name.substring(file.name.lastIndexOf('.'));
        let filePath = `upload/${date}/${fileNames}`;

        // this.$emit("dataChange", filePath, this.imageType, this.data);

        // 如果文件大于3M，那么进行压缩上传
        if (file.size >= 1024 * 1024 * 3) {
          let config = {
            width: 856, // 压缩后图片的宽
            height: 540, // 压缩后图片的高
            quality: 1, // 压缩后图片的清晰度，取值0-1，值越小，所绘制出的图像越模糊
          };
          compressImage(file, config).then(result => {
            let then = this;
            let reader2 = new FileReader();
            reader2.readAsArrayBuffer(result);
            reader2.onload = function (event) {
              // arrayBuffer转Buffer
              let buffer = objectToBuffer(event.target.result);
              client()
                .put(filePath, buffer)
                .then(ret => {
                  if (ret.name) {
                    // 获得图片地址
                    console.log(ret);
                    then.tu = ret.name;
                    option.onSuccess();
                  }
                });
            };
          });
        } else {
          client()
            .put(filePath, file)
            .then(ret => {
              if (ret.name) {
                // 获得图片地址
                this.tu = ret.name;
                console.log(ret);
                // this.$emit("dataChange", ret.name, this.imageType, this.data);
                option.onSuccess();
                // console.log(this.path+"2333")
                // console.log(this.ossPath+"oss")
              }
            });
        }
      } catch (error) {
        // this.disabled = false
        option.onError('上传失败');
      }
      // console.log(filePath)
    },
    // // 文件上传成功的函数
    async fnUploadSuccess (response, file, fileList) {
      console.log('ddd');
      console.log(this.tu);

      this.$emit('dataChange', this.tu, this.imageType, this.data);
    },
    // // 文件上传之前的函数
    // beforeUpload(file) {},
    // // 根据文件类型调用不同的接口
    // distinguish(fileName) {
    //   return new Promise(resolve => {
    //     if (this.imageType === '2') {
    //     } else if (this.imageType === '1') {
    //     } else if (this.imageType === '3') {
    //     } else if (this.imageType === '4') {
    //     } else {
    //       // 其他情况
    //       resolve({ flag: true, msg: '识别成功' });
    //     }
    //   });
    // },
  },
};
</script>
<style >
/* .el-upload--picture-card {
  width: 254px !important;
  height: 400px;
  line-height: 400px;
}
.el-upload-list__item-actions {
  width: 254px !important;
  height: 400px;
  line-height: 400px;
}
.el-upload-list__item-thumbnail,
.el-upload-list__item {
  width: 254px !important;
  line-height: 400px;
} */
</style>
