<template>
  <div class="plate-group flex flex-wrap items-center justify-between"
    style="justify-content: flex-start;width:1200px;max-width:1200px" v-if="!isLogin||wathc_video_face_need2">
    <PlateItem class="hover:shadow-xl" v-for="(item, index) in props.data" :key="index" :userinfo='userinfo'
      :wathc_video_face_need='wathc_video_face_need' :data="item" :intype='"Online"' />
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import PlateItem from './PlateItem';
import { getClassParamByKey, getUserInfoApi } from '@/api/index';
const wathc_video_face_need = ref(false)
const wathc_video_face_need2 = ref(false)
const store = useStore();
const isLogin = computed(() => Boolean(store.state.token));
const userinfo = ref({})
let pops = false
if (isLogin.value) {
  getClassParamByKey({ key: 'wathc_video_face_need' }).then(res => {
    console.log(res, 666)
    if (res && res.data && res.data == 2) {
      wathc_video_face_need.value = true
      console.log(wathc_video_face_need.value)
    }
    if (pops == true) {
      wathc_video_face_need2.value = true

    }
    pops = true


  })
  getUserInfoApi({}).then(res => {
    console.log(res)
    userinfo.value = res.data
    if (pops == true) {
      wathc_video_face_need2.value = true

    }
    pops = true
  })
}


const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});

// const imgurl =
//   'https://s.anw.red/news/1623152423.jpg!/both/800x450/quality/78/progressive/true/ignore-error/true';
//
// const groupList = Array.from({ length: 10 }).map(() => ({
//   imgurl,
//   title: '习近平新时代中国特色社会主义',
//   percentage: 20,
//   total: 12.58,
// }));

onMounted(() => {
  // console.log('$$$$$$$$$$$$$$$$$$$$$$$到了视频页面');
  // console.log(props.data);
});
// console.log(props);
</script>

<style lang="scss" scoped>
.plate-group {
  justify-content: flex-start;
  > div {
    &:not(:nth-child(n)) {
    }

    &:nth-child(n) {
      margin-top: 30px;
      margin-left: 14px;
      margin-right: 14px;
    }
  }
}
</style>
