let OSS = require('ali-oss');

/**
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 *  [data] 后端提供数据
 */
 let data = {
  region: 'oss-cn-shanghai',
  bucket: 'qjck',
  endpoint: 'https://oss-cn-shanghai.aliyuncs.com'
}
 export  function client () {
  var xhr = new XMLHttpRequest();  
  xhr.open("GET", "/api/blade-app/oss/getStsToken", false); // 第三个参数设置为false，表示同步请求  
 let getStsTokens = ''
  xhr.onreadystatechange = function () {  
  if (xhr.readyState == 4 && xhr.status == 200) {  
  // 请求成功完成，处理响应  
    getStsTokens = JSON.parse(xhr.responseText)
    getStsTokens.data = JSON.parse(Base64.decode(getStsTokens.data))
  }  
  };  
  xhr.send();  
    // 这里的代码会在xhr.send()调用后，且xhr.onreadystatechange中的处理函数执行完毕后才会执行  
  return  new OSS({
    region: data.region,
    bucket: data.bucket,
    endpoint: data.endpoint,
    accessKeyId:getStsTokens.data.bbb,
    accessKeySecret:getStsTokens.data.cc,
    stsToken:getStsTokens.data.aaaa,

  });
}


export function getObjectUrl (obj) {
  return 'https://qjck.oss-cn-shanghai.aliyuncs.com/' + obj;
}

// 下载pdf 拼接头
export const getDownloadUrl = (objectName, downLoadName) => {
  console.log(objectName);
  console.log(downLoadName);
  return ossClient.signatureUrl(objectName, {
    response: {
      'content-disposition': 'attachment; filename="' + downLoadName + '"',
    },
  });
};

// export const getObjectUrl = (objectName) => {
//   return ossClient.signatureUrl(objectName, {expires: 3600});
// }

export const objectToBuffer = object => {
  return new OSS.Buffer(object);
};
