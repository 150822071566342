<template>
  <n-config-provider :theme-overrides="themeOverrides" :locale="zhCN" :date-locale="dateZhCN">
    <n-message-provider>
      <AppContent />
    </n-message-provider>
  </n-config-provider>
</template>
<script setup>
import { zhCN, dateZhCN } from 'naive-ui';
import AppContent from './AppContent';

const themeOverrides = {
  common: {
    primaryColor: '#DF1A23',
    primaryColorHover: '#ff0a17',
    primaryColorPressed: '#ec101b',
  },
};
</script>

<style lang="scss">
.n-config-provider {
  background-color: #f4f4f4;
}
.queren_btn {
  background-color: #fff;
  border: 1px solid #2d60f6;
  color: #2d60f6;
  border-radius: 40px;
  font-size: 16px;
}
body {
  margin: 0 !important;
}
.n-breadcrumb-item__link {
  color: #333639 !important;
  font-size: 18px !important;
}
.n-config-provider {
  margin: 0 auto;
  min-width: 1400px;
}
body {
  color: #333;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.app-container {
  // max-width: 1508px;
  margin: 0 auto;
}
.app-container_warp {
  max-width: 1508px;
  margin: 0 auto;
}
.gray-bg {
  background-color: #efefef;
}
.df-bg {
  // background-color: rgb(0, 57, 137, 0.8);
  background-color: #2a458e;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
  box-shadow: 0px 28px 41px 14px rgba(188, 188, 188, 0.53);
}
.router-link-active {
  color: #fff;
  box-sizing: border-box;
  position: relative;
}
.router-link-active:after {
  border-bottom: 3px solid #fff;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.cff {
  color: #fff;
}
.n-pagination-item--active {
  color: #fff !important;
  background-color: #5e79c4 !important;
  border-radius: 50% !important;
  overflow: hidden;
  border: none !important;
}
.n-pagination-item {
  border-radius: 50% !important;
}
.erweima_content_text {
  color: #706f6f;
  font-size: 24px;
  line-height: 26px;
}
.erweima_logo_img {
  width: 276px;
  height: 42px;
  margin-bottom: 30px;
}
.flex_ambo {
  justify-content: center;
  align-items: center;
}
</style>
