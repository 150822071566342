<template>
  <Layout footer>
    <div class="app-container app-container_warp news_box">
      <div class="flex-center" style="width:100%;height:100%">
        <div class="news_left">
          <el-menu :unique-opened='true' style="border:none;" :default-active="defaultActive"
            class="el-menu-vertical-demo">
            <template v-for="(item,index) in channelList" :key="item.id">
              <el-sub-menu :index="index+1+''" v-if="item.children">
                <template #title>
                  <span>{{item.name}}</span>
                </template>
                <el-menu-item-group>
                  <template #title>
                  </template>
                  <el-menu-item v-for="(itemi,indexi) in item.children" @click="handleOpen(itemi)" :key="itemi.id"
                    :index="`${index+1}-${indexi+1}`">{{itemi.name}}</el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
              <el-menu-item @click="handleOpen(item)" v-else :index="index+1+''">
                <span>{{item.name}}</span>
              </el-menu-item>
            </template>
            <!-- <el-sub-menu index="1">
              <template #title>
                <span>工作动态</span>
              </template>
              <el-menu-item-group>
                <template #title>
                </template>
                <el-menu-item index="1-1">职业技能</el-menu-item>
                <el-menu-item @click="handleOpen(1)" index="1-2">工伤预防</el-menu-item>
              </el-menu-item-group>
            </el-sub-menu>
            <el-menu-item @click="handleOpen(2)" index="2">
              <span>通知公告</span>
            </el-menu-item>
            <el-menu-item @click="handleOpen(3)" index="3">
              <span>政策法规</span>
            </el-menu-item>
            <el-menu-item @click="handleOpen(4)" index="4">
              <span>下载中心</span>
            </el-menu-item> -->
          </el-menu>
        </div>
        <div class="news_right flex-center ">
          <NCarousel draggable v-if="newsBanner.length" class="news_right_main" style="width:760px;height:453px"
            autoplay>
            <div v-for="newsItem in newsBanner" :key="newsItem.id">
              <div class="cu news_right_main" style="margin-left:20px;" @click="tonews(newsItem,1)">
                <div>
                  <el-image style="width:760px;height:453px" fit="cover"
                    :src="`https://qjck.oss-cn-shanghai.aliyuncs.com/${newsItem.imgNewsUrl}`"></el-image>
                </div>
              </div>

            </div>

          </NCarousel>
          <!-- <div class="cu news_right_main" style="margin-left:20px;" @click="tonews(newsItem,1)"
            v-if="newsItem&&newsItem.imgNewsUrl">
            <div>
              <el-image style="width:760px;height:453px"
                :src="`https://qjck.oss-cn-shanghai.aliyuncs.com/${newsItem.imgNewsUrl}`"></el-image>
            </div>
          </div> -->
          <!-- <div class="news_right_main" style="margin-left:20px;" v-else-if="channelItem.picture">
            <div>
              <el-image style="width:760px;height:453px"
                :src="`https://qjck.oss-cn-shanghai.aliyuncs.com/${channelItem.picture}`"></el-image>
            </div>
          </div> -->

          <div style="margin-left:20px;"></div>
          <div class="news_right_titlist" style="flex:1">
            <ul style="overflow: hidden;height:408px" v-if="channelNewsList&&channelNewsList.length">
              <li class="news_right_titlist_li flex-center" style="padding-right:32px;" @click="tonews(i,1)"
                v-for="i in channelNewsList" :key="i.id">
                <div class="news_right_titlist_li_left">
                  <div>
                    {{i.releaseDate.substring(5,11)}}
                  </div>
                </div>
                <div style="flex:1" class="title_text">
                  {{i.title}}
                </div>
              </li>

            </ul>
            <NEmpty v-if="!channelNewsList.length" class="my-10" description="暂无数据" />
            <div @click="tonews(channelItem)" v-if="channelNewsList&&channelNewsList.length" class="genghduo">更多
              <span class="gengduo_r">
                <el-icon>
                  <ArrowRightBold />
                </el-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fenlei ">

      {{'全部课程分类'}}
    </div>

    <div class="flex app-container_warp fenlei_btn app-container" v-if="1" style="margin-top:70px">
      <el-button @click="tabbtnindex(2002)" style="background-color:transparent" class="fenlei_btn_in"
        :type="btnindex==2002?'primary':''" round>公共课程
      </el-button>
      <el-button @click="tabbtnindex(102)" class="fenlei_btn_in" :type="btnindex==102?'primary':''" round>创业培训
      </el-button>
      <el-button @click="tabbtnindex(101)" class="fenlei_btn_in" :type="btnindex==101?'primary':''" round>技能培训
      </el-button>

      <!-- <el-button @click="tabbtnindex(103)" class="fenlei_btn_in" :type="btnindex==103?'primary':''" round>创业提升培训
      </el-button> -->
      <el-button @click="tabbtnindex(110)" class="fenlei_btn_in" :type="btnindex==110?'primary':''" round>工伤预防
      </el-button>

      <el-button @click="tabbtnindex(2003)" class="fenlei_btn_in" :type="btnindex==2003?'primary':''" round>专项能力
      </el-button>
      <el-button @click="handleClassMore2('all')" class="fenlei_btn_in fenlei_btn_in_more" round>
        查看更多
        <el-icon style="font-size:22px;">
          <ArrowRightBold />
        </el-icon>
        <!-- > -->
      </el-button>

    </div>
    <div class="app-container app-container_warp">
      <div v-if="1">
        <div v-if="dataRow2.length">

          <div class="flex classss flw">
            <PlateItem class='PlateItemmar' :key='itemi.id+wathc_video_face_need' :data="itemi"
              :wathc_video_face_need='wathc_video_face_need' v-for="itemi in dataRow2" :userinfo='userinfo'></PlateItem>
          </div>
        </div>

        <!-- <NEmpty class="my-10" description="暂无数据" v-else /> -->
      </div>
      <div v-if="0">
        <div v-if="classList&&classList[0]&&classList[0].data.length">

          <div class="flex classss flw">
            <PlateItem class='PlateItemmar' :wathc_video_face_need='wathc_video_face_need'
              v-for="itemi in classList[0].data" :key='itemi.id+wathc_video_face_need' :userinfo='userinfo'
              :data="itemi">
            </PlateItem>
          </div>
        </div>

      </div>
      <div class="loock_more" @click="handleClassMore2(btnindex)" style=''>
        <el-icon style="font-size:27px;padding-top:4px;margin-right:17px;">
          <ChatDotRound />
        </el-icon>查看更多
      </div>
    </div>
    <div style="height:20px;"></div>
    <div class="pr flex-center" style=" height: auto;">
      <div class="pr pa app-container_warp"
        style="width:100%;color: rgb(255, 255, 255); margin: 0px auto; z-index: 1;text-align: left; line-height: 2em;font-family:SourceHanSansCN-Light;">
        <div style="font-size: 38px;">集团简介</div>
        <div class="mt30" style="width: 536px;margin-top:50px;font-size:17px;text-indent: 2em;">
          山东人才发展集团有限公司（简称“山东人才集团”）是经山东省人民政府批准成立的省属一级、功能型商业二类国有企业，省委组织部负责业务指导，于2021年2月注册成立。<br>
          <p style="text-indent: 2em;">
            山东人才集团按照创新思维、全球视野、市场导向、国企责任的发展思路，秉承“以人才为本，助力价值创造，实现共创共赢”的理念，致力打造全国一流的专业化、市场化、产业化、国际化人才发展全链条全周期服务企业。
          </p>
        </div>
        <el-link href="https://jppt.sd-talent.cn/" target="_blank">
          <button class="el-button el-button--success mt30" type="button"
            style="margin-top:30px;font-size:19px;padding:12px 50px">
            <!--v-if-->

            <!--v-if--><a url='https://jppt.sd-talent.cn/' target="_blank">进入集团官网</a>
          </button>
        </el-link>
      </div>
      <img src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/centerbg.cb987a26.png"
        style="width: 100%; left: 0px; top: 0px; z-index: 0;">
    </div>
    <div class="lianxiwomen" style=" font-family: SourceHanSansCN;">
      <div class="mt50" style="font-size: 50px;font-weight: 700;margin-top:50px">[ 联系我们 ]</div>
      <div class="f12 mb40 mt10" style="color:#999;font-size:16px;font-weight: 700;">请留下您的意见和建议，以便我们为您提供更优质的服务</div>
      <form class="el-form" style="margin: 0px auto; width: 920px;">
        <div class="el-row flex-space">
          <div class="el-col el-col-11">
            <div class="el-form-item">
              <!--v-if-->
              <div class="el-form-item__content">
                <div class="el-input">
                  <!-- 前置元素 -->
                  <!--v-if--><input class="el-input__inner" style="font-size:23px;height:60px"
                    v-model='postsaveContactdata.name' type="text" autocomplete="off" placeholder="姓名：">
                  <!-- 前置内容 -->
                  <!--v-if-->
                  <!-- 后置内容 -->
                  <!--v-if-->
                  <!-- 后置元素 -->
                  <!--v-if-->
                  <!--v-if-->
                </div>
                <!--v-if-->
              </div>
            </div>
          </div>
          <div class="el-col el-col-11">
            <div class="el-form-item">
              <!--v-if-->
              <div class="el-form-item__content">
                <div class="el-input">
                  <!-- 前置元素 -->
                  <!--v-if--><input class="el-input__inner" style="font-size:23px;height:60px"
                    v-model='postsaveContactdata.email' type="text" autocomplete="off" placeholder="邮箱：">
                  <!-- 前置内容 -->
                  <!--v-if-->
                  <!-- 后置内容 -->
                  <!--v-if-->
                  <!-- 后置元素 -->
                  <!--v-if-->
                  <!--v-if-->
                </div>
                <!--v-if-->
              </div>
            </div>
          </div>
        </div>
        <div class="el-row flex-space">
          <div class="el-col el-col-11">
            <div class="el-form-item">
              <!--v-if-->
              <div class="el-form-item__content">
                <div class="el-input">
                  <!-- 前置元素 -->
                  <!--v-if--><input class="el-input__inner" style="font-size:23px;height:60px"
                    v-model='postsaveContactdata.phone' type="text" autocomplete="off" placeholder="电话：">
                  <!-- 前置内容 -->
                  <!--v-if-->
                  <!-- 后置内容 -->
                  <!--v-if-->
                  <!-- 后置元素 -->
                  <!--v-if-->
                  <!--v-if-->
                </div>
                <!--v-if-->
              </div>
            </div>
          </div>
          <div class="el-col el-col-11">
            <div class="el-form-item">
              <!--v-if-->
              <div class="el-form-item__content">
                <div class="el-input">
                  <!-- 前置元素 -->
                  <!--v-if--><input class="el-input__inner" style="font-size:23px;height:60px"
                    v-model='postsaveContactdata.addr' type="text" autocomplete="off" placeholder="地址：">
                  <!-- 前置内容 -->
                  <!--v-if-->
                  <!-- 后置内容 -->
                  <!--v-if-->
                  <!-- 后置元素 -->
                  <!--v-if-->
                  <!--v-if-->
                </div>
                <!--v-if-->
              </div>
            </div>
          </div>
        </div>
        <div class="el-form-item">
          <!--v-if-->
          <div class="el-form-item__content">
            <div class="el-textarea"><textarea class="el-textarea__inner" autocomplete="off"
                placeholder="您想对我们说的话......" v-model='postsaveContactdata.content'
                style="min-height: 63px; margin-top: 0px; margin-bottom: 0px; height: 206px;font-size:23px">
    </textarea>
              <!--v-if-->
            </div>
            <!--v-if-->
          </div>
        </div>
        <button class="el-button el-button--default el-button--small mt30" type="button" @click="postsaveContact"
          style="background: linear-gradient(90deg,#4CAEFE, #02E1FE ); color: rgb(255, 255, 255);box-shadow: -1px 16px 51px 0px rgba(134,134,134,0.39); width: 580px; border: 0px;  margin: 30px auto;  display: block;height: 70px;font-size:24px;margin-top:50px;">
          <!--v-if-->
          <!--v-if--><span>提交您的信息</span>
        </button>
      </form>
    </div>
    <div class="flex contact_box" style=" font-weight: 700;">
      <div class="contact_bg">
        <div class="contact_ico">
          <el-image src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/contact_ico1.png"></el-image>

        </div>
        <div class="contact_title">
          公司地址
        </div>
        <div class="contact_text">
          济南市历城区华龙路1825号嘉恒商务大厦Ｂ座2601
        </div>
        <div class="contact_btn">
          欢迎咨询
        </div>
      </div>
      <div class="contact_bg">
        <div class="contact_ico">
          <el-image src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/contact_ico2.png"></el-image>

        </div>
        <div class="contact_title">
          咨询电话
        </div>
        <div class="contact_text" style="margin-top:48px">
          0531－81182679
        </div>
        <!-- <div class="contact_title" style="margin-top:-0px">
          举报电话
        </div>
        <div class="contact_text" style="margin-top:8px">
          0531－88900415
        </div> -->
        <div class="contact_btn">
          欢迎咨询
        </div>
      </div>
      <div class="contact_bg">
        <div class="contact_ico">
          <el-image src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/contact_ico3.png"></el-image>

        </div>
        <div class="contact_title">
          企业邮箱
        </div>
        <div class="contact_text" style="margin-top:48px">
          sdbyspx@163.com
        </div>
        <div class="contact_btn">
          欢迎咨询
        </div>
      </div>
    </div>
    <!--    学习步骤 开 始-->
    <!-- <div class="gray-bg">
      <div class="app-container step align-text relative flex items-center justify-center">
        <img class="bg" src="https://qjck.oss-cn-shanghai.aliyuncs.com/qjsite/img/img/arrow-rt.png" alt="" />
        <div class="step-item flex flex-col items-center justify-center" :key="index" v-for="(item, index) in stepList">
          <img :src="item.url" alt="" />
          <span class="mt-1">{{ item.text }}</span>
        </div>
      </div>
    </div> -->
    <!--    学习步骤 结束-->

    <!--    课程开始-->
    <!-- <NEmpty class="my-10" description="暂无数据" v-if="!contentLoading && classList.length === 0" />
    <NSpin :show="contentLoading" size="large" v-else>
      <div class="app-container pb-14" v-for="group in classList" :key="group.index">
        <div class="plate-header">
          <div class="lt text-center">{{ group.title }}</div>
          <div class="rt">
            <span class="mr-5 text-gray-700 text-base">全部课程（{{ group.total }}）</span>
          </div>
        </div>
        <Plate :data="group.data" />
        <n-button strong secondary style="margin: 0 auto;display:block;margin-top:30px"
          @click="handleClassMore(group.title)">查看更多
        </n-button>

      </div>
    </NSpin> -->
  </Layout>
</template>

<script setup>
import {
  computed,
  ref,
  shallowReactive,
  shallowRef,
  onMounted,
  onBeforeUpdate,
  reactive,
  watch,
  nextTick,
  getCurrentInstance,

} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Layout from '@/components/Layout';
import ArrowRightButton from '@/components/ArrowRightButton';
import Plate from '@/components/Plate';
import Notice from '@/components/Notice';
import { getHomeBannerApi, getHomeNoticeApi, getClassListApi, getUserInfoApi, getClassParamByKey, pageByCategory, siteChannel, channelNews, saveContact } from '@/api';
import PlateItem from '@/components/PlateItem';
const store = useStore();
const router = useRouter();

const isLogin = computed(() => Boolean(store.state.token));
const isLoginindex = ref(isLogin.value);
const channelNewsList = ref([])
const channelItem = ref({})
const newsItem = ref({})
const newsBanner = ref([])
const swiperLoading2 = ref(true)
const handleOpen = (item) => {
  channelItem.value = item
  channelNews({ channelId: item.id, size: 11 }).then(res => {
    console.log(res)
    let newsItems = ''
    res.data.records.forEach(item => {
      if (item.imgNewsUrl && !newsItems) {
        newsItems = item
      }
    })
    console.log(newsItems)
    newsItem.value = newsItems || {}
    channelNewsList.value = res.data.records

  })
}
channelNews({ site: 2, size: 5, imgNews: 1 }).then(res => {
  console.log(res, '轮播新闻')
  newsBanner.value = res.data.records
  swiperLoading2.value = true
})
function tonews (item, type) {
  if (type == 1) {
    console.log(item)
    router.push('/news?id=' + channelItem.value.id + '&newid=' + item.id);

  } else {
    router.push('/news?id=' + item.id);
  }
  // 
}
const newList = ref({});
const channelList = ref([])
const defaultActive = ref('1-1')
siteChannel({ site: 2, category: 1 }).then(res => {
  console.log(res, '新闻栏目')
  channelList.value = res.data
  if (res.data[0]) {
    if (res.data[0].children) {
      defaultActive.value = '1-1'
      handleOpen(res.data[0].children[0])
    } else {
      defaultActive.value = '1'
      handleOpen(res.data[0])
    }
  }
})
pageByCategory({ category: 310, current: 1, size: 7 }).then(res => {
  console.log(res, '新闻动态')
  // newList.value = res.data.records
})
const stepList = [
  '注册',
  'APP 下载',
  '上传认证',
  '实人认证',
  '扫码登录',
  '开始学习',
  '认证考试',
  '选择机构',
  '实训',
  '结业',
].map((v, i) => ({ url: require(`@/assets/img/home-icon-${i}.png`), text: v }));

let carouselList = shallowRef([]);
const swiperLoading = ref(true);
const btnindex = ref(2002);
const wathc_video_face_need = ref(false)
const wathc_video_face_need2 = ref(false)
function tabbtnindex (index) {
  btnindex.value = index
  getClassList(btnindex.value)
}
getHomeBannerApi({ site: 2 })
  .then(res => res.data)
  .then(res => {
    carouselList.value = res;
  })
  .finally(() => (swiperLoading.value = false));

const noticeList = shallowRef([]);
const noticeLoading = ref(true);
getHomeNoticeApi()
  .then(res => res.data)
  .then(res => {
    res = res.map(v => v.title);
    noticeList.value = res;
  })
  .finally(() => (noticeLoading.value = false));

const classList = shallowRef([]);
const dataRow1 = shallowRef([]);
const dataRow2 = shallowRef([]);
const dataRow3 = shallowRef([]);

const contentLoading = ref(true);
const titles = ['公共课程', '职业技能', '创业培训'];
const businessType5 = { current: '1', size: 12 };
const userinfo = ref({})
const popops = ref(false)

if (isLogin.value) {
  getUserInfoApi({}).then(res => {
    // console.log(res)
    userinfo.value = res.data
    popops.value = true

  })
} else {
  popops.value = true
}
const postsaveContactdata = ref({})
function estPhone (str) {
  const reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/;
  return reg.test(str);
}
function postsaveContact () {
  console.log(postsaveContactdata.value.content)
  if (!postsaveContactdata.value.name) {
    window.message.error('请输入姓名！');
    return
  }
  if (!postsaveContactdata.value.email) {
    window.message.error('请输入邮箱！');
    return
  }
  if (!estPhone(postsaveContactdata.value.phone)) {
    window.message.error('请输入正确电话！');
    return
  }
  if (!postsaveContactdata.value.addr) {
    window.message.error('请输入地址！');
    return
  }
  if (!postsaveContactdata.value.content) {
    window.message.error('请输入您想对我们说的话...');
    return
  }
  postsaveContactdata.value.site = 2
  saveContact(postsaveContactdata.value).then(res => {
    window.message.success('您的信息已经收到，我们尽快会与您联系！');
    postsaveContactdata.value = {}
  })

}
Promise.all(titles.map((item, index) => getClassListApi({ ...businessType5, pageSource: 'home' })))
  .then(res => {
    return res.map((item, index) => {
      const data = item.data.records;
      return {
        title: titles[index],
        index,
        data,
        total: item.data.total,
      };
    });
  })
  .then(res => {
    // console.log(res)
    if (isLogin.value) {
      console.log('登录了', res);

      classList.value = res;
      classList.value.splice(1);
      classList.value[0].title = '我的课程';
      // console.log(classList.value);
      // console.log('登录了请求')
      getUserInfoApi({}).then(res => {
        // console.log(res)
        userinfo.value = res.data
        wathc_video_face_need2.value = true
        getClassParamByKey({ key: 'wathc_video_face_need' }).then(res => {
          // console.log(res)
          if (res && res.data && res.data == 2) {
            wathc_video_face_need.value = true
            // console.log(wathc_video_face_need.value, 123)
            let { ctx } = getCurrentInstance()
            ctx.$forceUpdate()
          }
        })

      })
    } else {
      classList.value = res;

      classList.value[0].total = dataRow1.value.total;
      classList.value[0].data = dataRow1.value.records;

      classList.value[1].total = dataRow2.value.total;
      classList.value[1].data = dataRow2.value.records;

      classList.value[2].total = dataRow3.value.total;
      classList.value[2].data = dataRow3.value.records;
      // console.log('没有登录');
    }
    // let { ctx } = getCurrentInstance()
    // ctx.$forceUpdate()
  })
  .finally(() => (contentLoading.value = false));

// 首页 点击全部课程 调转到对应的位置
function handleClassMore (type) {
  // console.log('首页进入线上学习1111111111111111');
  // console.log(type);
  if (type === '公共课程') {
    // console.log('公共课程');
    router.push('/learning-center/online?type=2002');
  } else if (type === '职业技能') {
    // console.log('职业技能');
    router.push('/learning-center/online?type=101');
  } else if (type === '创业培训') {
    // console.log('创业培训');
    router.push('/learning-center/online?type=102');
  } else if (type === '我的课程') {
    router.push('/learning-center/online');
    // console.log('我是公共课程');
  }
  // router.push('/learning-center/online');
  // if (isLogin.value) {
  //   router.push('/learning-center/online');
  // } else {
  //   window.message.warning('请您未登录，请先登录');
  // }
}
function handleClassMore2 (type) {
  // console.log('首页进入线上学习1111111111111111');
  // console.log(type);
  // if (type === '公共课程') {
  //   // console.log('公共课程');
  //   router.push('/learning-center/online?type=2002');
  // } else if (type === '职业技能') {
  //   // console.log('职业技能');
  //   router.push('/learning-center/online?type=101');
  // } else if (type === '创业培训') {
  //   // console.log('创业培训');
  //   router.push('/learning-center/online?type=102');
  // } else if (type === '我的课程') {
  //   router.push('/learning-center/online');
  //   // console.log('我是公共课程');
  // }
  if ('all') {
    router.push('/learning-center/online');

    return
  }
  router.push('/learning-center/online?type=' + type);
  // if (isLogin.value) {
  //   router.push('/learning-center/online');
  // } else {
  //   window.message.warning('请您未登录，请先登录');
  // }
}

const abc = shallowRef([
  {
    title: '公共课程',
    data: [],
    index: 0,
    total: '',
  },
  {
    title: '职业技能',
    data: [],
    index: 1,
    total: '',
  },
  {
    title: '创业培训',
    data: [],
    index: 2,
    total: '',
  },
]);

// 搜索调用 type 1是登陆 0是没登录
function initialHome (type, name) {
  // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
  // console.log(abc.value);
  classList.value = [];
  if (abc.value.length === 3) {
    abc.value[0].data = [];
    abc.value[1].data = [];
    abc.value[2].data = [];
  }

  const businessType1 = { businessType: type === 0 ? '2002' : null, courseName: name, size: 12, pageSource: 'home' };
  getClassListApi(businessType1)
    .then(res => res.data)
    .then(res => {
      dataRow1.value = res;
      // 判断是否登录
      if (isLogin.value) {
        abc.value[0].total = dataRow1.value.total;
        abc.value[0].data = dataRow1.value.records;
        abc.value[0].title = '我的课程';
        // abc.value.splice(1);
        if (res.records.length !== 0) {
          classList.value = abc.value;
          // console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
          classList.value.splice(1);
          // console.log(classList);
        }
        getClassParamByKey({ key: 'wathc_video_face_need' }).then(res => {
          // console.log(res)
          if (res && res.data && res.data == 2) {
            wathc_video_face_need.value = true
            // console.log(wathc_video_face_need.value, 123)
            let { ctx } = getCurrentInstance()
            ctx.$forceUpdate()
          }
          if (pops == true) {
            wathc_video_face_need2.value = true


          }
          pops = true


        })
      } else {
        // console.log('没登录');
        abc.value[0].total = dataRow1.value.total;
        abc.value[0].data = dataRow1.value.records;
        if (res.records.length !== 0) {
          classList.value = abc.value;
        }
        // console.log('查询数据1');
      }
    });

  const businessType2 = { businessType: type === 0 ? '101' : null, courseName: name, size: 12, pageSource: 'home' };
  getClassListApi(businessType2)
    .then(res => res.data)
    .then(res => {
      dataRow2.value = res;

      if (isLogin.value) {
        // classList.value = res.records;
        console.log('登录搜索 2');
        // console.log(classList.value);
      } else {
        abc.value[1].total = dataRow2.value.total;
        abc.value[1].data = dataRow2.value.records;
        if (res.records.length !== 0) {
          classList.value = abc.value;
        }
        // console.log('查询数据2');
        // console.log(abc.value);
      }
    });

  const businessType3 = { businessType: type === 0 ? '102' : null, courseName: name, size: 12, pageSource: 'home' };
  getClassListApi(businessType3)
    .then(res => res.data)
    .then(res => {
      dataRow3.value = res;

      if (isLogin.value) {
        // classList.value = res.records;
      } else {
        abc.value[2].total = dataRow3.value.total;
        abc.value[2].data = dataRow3.value.records;
        if (res.records.length !== 0) {
          classList.value = abc.value;
        }
      }

    });
}

// 搜索
const searchNO = computed(() => store.state.search);
const registerYes = computed(() => Boolean(store.state.token));
watch(searchNO, () => {
  if (registerYes.value) {
    // console.log('登录 我监听vuex Home');
    initialHome(1, store.state.search);
  } else {
    // console.log('没登录 我监听vuex Home');
    initialHome(0, store.state.search);
  }
});

function getClassList (businessType) {
  const businessType1 = { businessType, size: 12, pageSource: 'home' };
  getClassListApi(businessType1)
    .then(res => res.data)
    .then(res => {
      console.log('???')
      console.log(res)
      let arr = []
      let arr1 = []
      let arr2 = []
      res.records.forEach((element, index) => {
        if (index < 4) {
          arr1.push(element)
        } else {
          arr2.push(element)
        }
      });
      if (arr1.length) {
        arr.push(arr1)
      }
      if (arr2.length) {
        arr.push(arr2)
      }
      if (isLogin.value) {
      } else {
        if (businessType1.businessType == 2002) {
          arr = [[{
            "createUser": "1123598821738675201",
            "createTime": "2022-09-07 14:27:36",
            "updateUser": "1123598821738675201",
            "updateTime": "2022-09-07 16:38:37",
            "status": 1,
            "isDeleted": 0,
            "id": "1567399163367264258",
            "courseName": "消防安全（济南）",
            "courseType": 0,
            "microType": 0,
            "validTime": "2022-09-07",
            "lecturerId": "青桔创课",
            "profilePicture": "upload/2022-09-07/ec83287c-c601-440c-8f58-a36b4b3f59eb.jpg",
            "teacherPicture": "",
            "course": "<p>消防安全</p>",
            "tenantId": "000000",
            "isInherit": 1,
            "businessType": "2002",
            "isVaild": 0,
            "classId": -1,
            "credit": 118,
            "learned": 0,
            "learningProgress": 0,
            "viewCount": -1,
            "trainPost": "",
            "sortNo": 4
          }]]
        }
        if (businessType1.businessType == 102) {
          arr = [[{
            "createUser": "1123598821738675201",
            "createTime": "2022-09-07 11:10:28",
            "updateUser": "1123598821738675201",
            "updateTime": "2022-09-07 16:55:24",
            "status": 1,
            "isDeleted": 0,
            "id": "1567349552476852226",
            "courseName": "创业意识培训（济南）",
            "courseType": 0,
            "microType": 0,
            "validTime": "2021-10-09",
            "lecturerId": "青桔创课",
            "profilePicture": "upload/2022-09-07/ffa2388e-aebc-48d1-8f87-0a46153d610c.jpg",
            "teacherPicture": "",
            "course": "<p>创业意识培训</p>",
            "tenantId": "000000",
            "isInherit": 1,
            "businessType": "102",
            "isVaild": 0,
            "classId": -1,
            "credit": 1188,
            "learned": 0,
            "learningProgress": 0,
            "viewCount": 13,
            "trainPost": "",
            "sortNo": -1
          }, {
            "createUser": "1123598821738675201",
            "createTime": "2022-09-07 11:21:12",
            "updateUser": "1123598821738675201",
            "updateTime": "2022-09-07 16:55:05",
            "status": 1,
            "isDeleted": 0,
            "id": "1567352252195147778",
            "courseName": "创业能力培训（济南）",
            "courseType": 0,
            "microType": 0,
            "validTime": "2022-09-07",
            "lecturerId": "青桔创课",
            "profilePicture": "upload/2022-09-07/da1084a0-8671-4d43-84d5-44cddd8a44a5.jpg",
            "teacherPicture": "",
            "course": "<p>创业能力培训</p>",
            "tenantId": "000000",
            "isInherit": 1,
            "businessType": "102",
            "isVaild": 0,
            "classId": -1,
            "credit": 2234,
            "learned": 0,
            "learningProgress": 0,
            "viewCount": -1,
            "trainPost": "",
            "sortNo": -1
          },]]
        }
        if (businessType1.businessType == 101) {
          arr = [[
            {
              "createUser": "1123598821738675201",
              "createTime": "2022-07-07 15:53:51",
              "updateUser": "1123598821738675201",
              "updateTime": "2022-09-26 14:38:44",
              "status": 1,
              "isDeleted": 0,
              "id": "1544952822640939010",
              "courseName": "劳动关系协调员",
              "courseType": 0,
              "microType": 0,
              "validTime": "2022-07-07",
              "lecturerId": "青桔创课",
              "profilePicture": "upload/2022-07-07/fc5526b9-0569-412e-8e01-e1dbab729cc1.png",
              "teacherPicture": "",
              "course": "<p>劳动关系协调员</p >",
              "tenantId": "000000",
              "isInherit": 1,
              "businessType": "101",
              "isVaild": 0,
              "classId": -1,
              "credit": 1867,
              "learned": 0,
              "learningProgress": 0,
              "viewCount": 871,
              "trainPost": "100009",
              "sortNo": -1
            }, {
              "createUser": "1123598821738675201",
              "createTime": "2022-05-16 11:57:58",
              "updateUser": "1123598821738675201",
              "updateTime": "2022-09-07 17:17:43",
              "status": 1,
              "isDeleted": 0,
              "id": "1526049289875230721",
              "courseName": "养老护理员（济南）",
              "courseType": 0,
              "microType": 0,
              "validTime": "2022-05-16",
              "lecturerId": "王小伟",
              "profilePicture": "upload/2022-05-16/eeff12f0-dd52-45b5-8309-ee52eb5b0511.png",
              "teacherPicture": "",
              "course": "<p>老年人照护</p>",
              "tenantId": "000000",
              "isInherit": 1,
              "businessType": "101",
              "isVaild": 0,
              "classId": -1,
              "credit": 1797,
              "learned": 0,
              "learningProgress": 0,
              "viewCount": 12,
              "trainPost": "",
              "sortNo": -1
            },
            {
              "createUser": "1511614056513933313",
              "createTime": "2022-06-27 10:07:06",
              "updateUser": "1511614056513933313",
              "updateTime": "2022-06-30 12:24:05",
              "status": 1,
              "isDeleted": 0,
              "id": "1541241680345174018",
              "courseName": "育婴员",
              "courseType": 0,
              "microType": 0,
              "validTime": "2022-06-27",
              "lecturerId": "小王老师",
              "profilePicture": "upload/2022-06-27/1974f245-aa24-45c9-8189-f40b6817b07d.jpg",
              "teacherPicture": "",
              "course": "<p>育婴员是主要从事0-3岁婴幼儿照料、护理和教育，同时可以指导家长科学育儿的人员。</p>",
              "tenantId": "164232",
              "isInherit": 0,
              "businessType": "101",
              "isVaild": 0,
              "classId": -1,
              "credit": 1628,
              "learned": 0,
              "learningProgress": 0,
              "viewCount": 2348,
              "trainPost": "100000",
              "sortNo": -1
            }, {
              "createUser": "1123598821738675201",
              "createTime": "2022-06-16 17:35:24",
              "updateUser": "1123598821738675201",
              "updateTime": "2022-09-07 17:17:21",
              "status": 1,
              "isDeleted": 0,
              "id": "1537368232690716674",
              "courseName": "茶艺师（济南）",
              "courseType": 0,
              "microType": 0,
              "validTime": "2022-06-16",
              "lecturerId": "青桔创课",
              "profilePicture": "upload/2022-06-16/18eb2164-5740-4a1b-8ef5-eccb876447cc.png",
              "teacherPicture": "",
              "course": "<p>茶艺师</p>",
              "tenantId": "000000",
              "isInherit": 1,
              "businessType": "101",
              "isVaild": 0,
              "classId": -1,
              "credit": 1708,
              "learned": 0,
              "learningProgress": 0,
              "viewCount": -1,
              "trainPost": "",
              "sortNo": -1
            },]]
        }
        if (businessType1.businessType == 2002) {
          arr = [[
            {
              "createUser": "1123598821738675201",
              "createTime": "2022-09-08 16:56:04",
              "updateUser": "1123598821738675201",
              "updateTime": "2022-09-08 16:56:04",
              "status": 1,
              "isDeleted": 0,
              "id": "1567798914034176002",
              "courseName": "职业指导师（济南）",
              "courseType": 0,
              "microType": 0,
              "validTime": "2022-09-08",
              "lecturerId": "青桔创课",
              "profilePicture": "upload/2022-09-08/51f46250-996f-4880-80e9-04eb300c5b19.jpg",
              "teacherPicture": "",
              "course": "<p>职业指导师</p>",
              "tenantId": "000000",
              "isInherit": 1,
              "businessType": "101",
              "isVaild": 0,
              "classId": -1,
              "credit": 1003,
              "learned": 0,
              "learningProgress": 0,
              "viewCount": -1,
              "trainPost": "",
              "sortNo": -1
            },
          ], []]
        }
      }
      dataRow2.value = res.records
    });
}
onMounted(() => {
  getClassList(btnindex.value)
  // const businessType1 = { businessType: '101', size: 8 };
  // getClassListApi(businessType1)
  //   .then(res => res.data)
  //   .then(res => {
  //     console.log(res)
  //     let arr = []
  //     let arr1 = []
  //     let arr2 = []
  //     res.records.forEach((element, index) => {
  //       if (index < 4) {
  //         arr1.push(element)
  //       } else {
  //         arr2.push(element)
  //       }
  //     });
  //     if (arr1.length) {
  //       arr.push(arr1)
  //     }
  //     if (arr2.length) {
  //       arr.push(arr2)
  //     }
  //     console.log(arr)
  //   });

  // const businessType2 = { businessType: '101', size: 12 };
  // getClassListApi(businessType2)
  //   .then(res => res.data)
  //   .then(res => {
  //     dataRow2.value = res;
  //   });

  // const businessType3 = { businessType: '102', size: 12 };
  // getClassListApi(businessType3)
  //   .then(res => res.data)
  //   .then(res => {
  //     dataRow3.value = res;
  //   });
});
</script>

<style lang="scss" scoped>
@media (max-width: 1500px) {
  .classss {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.gengduo_r {
  display: inline-block;
  border: 1px solid #999;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  text-align: center;
  line-height: 20px;
}
.title_text {
  width: 300px; //设置宽
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
  // 控制行数
  -webkit-line-clamp: 1; //超出两行隐藏
  -webkit-box-orient: vertical; // 从上到下垂直排列子元素
  // font-size: 20px;
  letter-spacing: 1px;
  //（设置伸缩盒子的子元素排列方式）
}
.banner {
  width: 100%;
  height: 421px;
  background-image: url('https://qjck.oss-cn-shanghai.aliyuncs.com/xiangyun/gw/banner1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.carousel-img {
  width: 100%;
  height: 420px;
  object-fit: cover;
}
.fenlei {
  line-height: 50px;
  font-size: 48px;
  position: relative;
  margin: 60px 0 45px;
  text-align: center;
}
.fenlei::after {
  content: 'CLASSIFICATION OF ALL COURSES';
  width: 100%;
  text-align: center;
  left: 0;
  bottom: -24px;
  font-size: 39px;
  color: #333;
  position: absolute;
  z-index: 1;
  font-weight: 700;
  opacity: 0.09;
}
:deep(.n-carousel) {
  .n-carousel__dots {
    bottom: 35px;
  }

  .n-carousel__dot {
    background-color: #afafaf !important;
    width: 12px;
    height: 12px;
  }

  .n-carousel__dot--active {
    background-color: #df1a23 !important;
  }
}

.step {
  height: 180px;

  .bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .step-item {
    width: 114px;
    height: 114px;
    background-color: #fff;
    border-radius: 50%;

    & + .step-item {
      margin-left: 20px;
    }
  }
}

.understand {
  background-color: #f5f6f9;
}

.plate-header {
  padding-top: 50px;
  padding-bottom: 35px;
}

.lt {
  width: 126px;
  font-size: 30px;
  padding-bottom: 5px;
  border-bottom: 3px solid #df1a23;
  position: relative;
  margin: 0 auto;
  &:after {
    content: '';
    position: absolute;
    left: -87px;
    bottom: -2px;
    width: 300px;
    height: 1px;
    background-color: #acacac;
    z-index: -1;
  }
}
.fenlei_btn {
  justify-content: space-between;
}
.flw {
  flex-wrap: wrap;
  // justify-content: space-between;
  // padding: 30px 120px;
  margin-top: 50px;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.mt50 {
  margin: 50px auto 0;
  text-align: center;
}
.mt10 {
  margin: 10px auto 40px;
  font-size: 12px;
  text-align: center;
}
.flex-space {
  justify-content: space-between;
  display: flex;
}
.news_left {
  width: 200px;
  height: 100%;
  padding-top: 30px;
  box-shadow: 15px -8px 44px 1px rgba(133, 133, 133, 0.19);
  z-index: 1;
  background-color: #fff;
}

.news_right {
  flex: 1;
  height: 483px;
  background-color: #fff;
}
.news_box {
  margin: 50px auto 0;
  width: 1508;
  height: 483px;
  box-shadow: -16px 4px 44px 1px rgba(133, 133, 133, 0.19);
}
.news_right_titlist {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  padding: 0 10px;
  background-color: #fff;
  padding-top: 30px;
}
.news_right_titlist_li {
  font-size: 16px;
  margin-bottom: 14px;
  cursor: pointer;
}
.news_right_titlist_li:hover .title_text {
  color: #679cff;
}
.news_right_titlist_li_left {
  color: #679cff;
  margin: 0 20px 0 10px;
  text-align: center;
  display: flex;
  width: 50px;
  flex-shrink: 0;
  font-weight: 700;
}
.genghduo {
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  margin-top: 6px;
  color: #999;
  cursor: pointer;
  padding-right: 20px;
}
.fenlei_btn_in {
  // width: 238px;
  padding: 12px 50px !important;
  height: 59px;
  box-sizing: border-box;
  font-weight: 400 !important;
  border-radius: 59px !important;
  color: #003989;
  background-color: transparent;
  font-size: 30px;
  border: 2px solid #96c2ff;
}
.fenlei_btn_in:hover {
  color: #003989;
}
.el-button--primary {
  font-weight: 500 !important;
}
.fenlei_btn_in_more {
  // border: none;
}
</style>
<style >
.el-input__inner,
.el-textarea__inner {
  border: 1px solid rgb(209, 207, 207) !important;
  font-family: SourceHanSansCN;
  font-weight: 400;
}
.el-input__inner::placeholder {
  color: #666666 !important;
  font-weight: 700 !important;
}
.el-textarea__inner::placeholder {
  /* color: #666666 !important; */
  /* font-weight: 700 !important; */
}
.fenlei_btn_in.el-button--primary {
  color: #fff !important;
  background-color: rgba(60, 111, 239) !important;
  border: none;
}
.news_box .el-sub-menu__title,
.news_box .el-menu-item {
  /* box-shadow: -16px 10px 22px 0px rgba(133, 133, 133, 0.19); */
  font-size: 22px;
  line-height: 58px;
  height: 58px;
  padding-left: 40px !important;
}
.news_box .el-menu-item-group {
  background: #f2f7ff;
  font-size: 18px;
  padding-bottom: 10px;
}
.news_box .el-menu-item-group .el-menu-item {
  font-size: 18px;
  width: 148px !important;
  height: 29px !important;
  line-height: 29px !important;
  min-width: 0 !important;
  margin: 17px auto;
  padding: 0 !important;
  text-align: center;
}
.el-sub-menu__icon-arrow {
  right: 30px !important;
  margin-top: -11px !important;
}
.is-opened .el-sub-menu__title {
  box-shadow: -16px 10px 22px 0px rgba(133, 133, 133, 0.19);
  z-index: 10;
}
.el-icon-arrow-down {
  font-size: 24px !important;
}
.news_box .el-menu-item-group .el-menu-item.is-active {
  background: linear-gradient(0deg, #4e77fc 0%, #3c6fef 51%, #4c6dff 100%);
  border-radius: 15px;
  color: #fff;
}
.loock_more {
  margin: 20px auto;
  margin-top: 40px;
  width: 308px;
  height: 71px;
  background: #ffffff;
  border: 2px solid #4c6dff !important;
  border-radius: 10px;
  padding: 0;
  display: block;
  line-height: 71px;
  text-align: center;
  font-size: 27px;
  color: #444444;
  cursor: pointer;
  background-color: transparent;
  /* border-image: linear-gradient(0deg, #4e77fc, #3c6fef, #4c6dff) 10 10 !important; */
}
.loock_more:hover {
  background-color: #c6e2ff;
  color: #409eff;
}
.contact_box {
  width: 1248px;
  justify-content: space-between;
  margin: 80px auto;
}
.contact_bg {
  background-image: url(https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/contact_bg.png);
  width: 364px;
  height: 458px;
  position: relative;
}
.contact_ico {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_title {
  height: 23px;
  font-size: 23px;
  /* font-family: SourceHanSansCN; */
  font-weight: 700;
  color: #666666;
  text-align: center;
  margin-top: 26px;
}
.contact_text {
  width: 249px;
  /* height: 68px; */
  font-size: 20px;
  font-weight: normal;
  /* font-family: SourceHanSansCN; */
  color: #888888;
  line-height: 48px;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
}
.contact_btn {
  width: 138px;
  height: 40px;
  background: linear-gradient(-90deg, #02e1fe, #4caefe);
  box-shadow: -1px 16px 51px 0px rgba(134, 134, 134, 0.39);
  border-radius: 5px;
  margin: 0 auto;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
}
.cu {
  cursor: pointer;
}
.serch_input .el-input__inner::placeholder {
  font-weight: normal !important;
}
.PlateItemmar {
  margin-right: 28px;
  margin-top: 20px;
}
.PlateItemmar:nth-child(4n) {
  margin-right: 0;
}
.news_right .n-carousel__dots {
  display: flex;
}
/*  */
</style>