<template>
  <div class="nav_header_box " v-if="0">
    <div class="app-container ">
      <div class="nav_header flex">
        <div class="nav_header_title">
          欢迎登录山东人才集团智慧人才服务平台！
        </div>
        <div class="flex" style="align-items: center;">
          <!-- <div>
            <n-input-group class="ml-auto w-auto seah">
              <n-input size="small" class="seah_input" v-model:value="searchContent.content" placeholder="请输入查询内容">
                <template #prefix>
                </template>
              </n-input>
              <n-button size="small" class="seah_btn" type="primary" @click="allSearch">搜索</n-button>
            </n-input-group>
          </div> -->
          <div class="flex nav_header_phone_box">
            <img class="nav_header_phone" src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/phone.png" alt="" />
            <div class="" style="flex-shrink: 0;">
              客服热线：0531－81182679
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
  <div style="background-color: rgb(255, 255, 255);">
    <div class="app-container_warp app-container header flex items-center bg-white">
      <!-- <img class="logo" src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/logoTop.1c148e0d1.png?n=3" alt="" /> -->
      <div class="flex">
        <!-- <img class="logo" style="margin-top:1px" src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/zhrclogo2.png?n=3"
          alt="" /> -->
        <img class="logo" :src="`https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/zhrclogo8.png`" alt="" />
      </div>

      <!-- <div></div> -->
      <div class=" flex" style="align-items: center;">
        <div class="serch_input_box_b">
          <div class="serch_input serch_input_box">
            <el-input class=" serch_input_box_in" v-model="sousuotext" placeholder="请输入查询内容">
            </el-input>
            <div class="serch_input_box_btn" @click="sousuo">
              <el-icon size='18px' style="font-size:22px;margin-right:8px">
                <Search />
              </el-icon>
              搜索
            </div>
          </div>
        </div>

        <template v-if="!isLogin">
          <div href="" style="font-size:22px; text-align:center;margin:0">
            <!-- 登录 -->
            <n-popover trigger="hover" placement="bottom">
              <template #trigger>
                <!-- <n-button type="primary" size="large">APP 下载</n-button> -->
                <div class="hovereff"
                  style="margin-left:55px;font-size:16px;height:30px;line-height:28px;  cursor: pointer;flex-shrink: 0;font-size:22px; text-align:center;margin:0">
                  登录
                </div>
              </template>
              <div style="font-size:36px">
                <div>
                  <el-button @click="handleLogin" class="fenlei_btn_in" type="primary" round>学员登录
                  </el-button>

                </div>
                <div>
                  <a href="https://login.sdzhrc.cn" target="_blank">
                    <el-button class="fenlei_btn_in" type="primary" round>管理入口
                    </el-button>
                  </a>

                </div>
              </div>
            </n-popover>
          </div>
          <span style="margin:0" class="marginoo">丨</span>
          <div @click="showRegisterModal = true" class="hovereff"
            style="font-size:22px; text-align:center;margin:0;text-decoration:none" href="">注册
          </div>

          <n-popover trigger="hover" placement="bottom">
            <template #trigger>
              <!-- <n-button type="primary" size="large">APP 下载</n-button> -->
              <div
                style="margin-left:40px;margin-right:8px;font-size:20px;height:30px;line-height:28px;  cursor: pointer;flex-shrink: 0;color:#606266">
                App下载
              </div>
            </template>

            <!-- https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/zhrclogo2.png -->
            <img class="w-36" src="https://qjck.oss-cn-shanghai.aliyuncs.com/handupload/appdown/zhrc.png" alt="" />
          </n-popover>

          <!-- <el-link @click=" showRegisterModal2=true" href="">专家登录</el-link> -->
          <!-- <n-button type="primary" size="large" @click="showRegisterModal = true">学员注册</n-button> -->
          <!-- <n-button type="primary" size="large" @click="handleLogin">学员登录</n-button> -->
          <!-- <n-button type="primary" size="large" @click="showRegisterModal2 = true">专家登录</n-button> -->
        </template>
        <div v-if="isLogin" class="personage_1" style="margin-right:10px;">
          <img class="flex-shrink-0" v-if="studentMessage.photo" style="width: 53px; height: 45px;"
            :src="imgBaseUrl + studentMessage.photo" alt="" />
          <img class="flex-shrink-0" v-else-if="studentMessage.sex==2" style="width: 53px; height: 45px"
            :src="`http://qjck.oss-cn-shanghai.aliyuncs.com/qjgw/nv.png`" alt="" />
          <img class="flex-shrink-0" v-else style="width: 53px; height: 45px"
            :src="`http://qjck.oss-cn-shanghai.aliyuncs.com/qjgw/nan.png`" alt="" />
        </div>

        <div v-if="isLogin" class="personage_2" style=" margin-right: 20px;">{{ studentMessage.stuName }} </div>
        <div v-if="isLogin" class="ambo">
          <el-progress width='45' style="margin:0 auto;" type="circle"
            :percentage="updatapercentage(credit.onlineCommonLearn,credit.onlineCommonRequire)">
          </el-progress>
          <div class="percentage-label">通识课</div>

        </div>
        <div v-if="isLogin" class="ambo">
          <el-progress width='45' style="margin:0 auto;" type="circle"
            :percentage="updatapercentage(credit.onlineMajorLearn,credit.onlineMajorRequire)">
          </el-progress>
          <div class="percentage-label">专业课</div>

        </div>
        <div @click="router.push('/user')" v-if="isLogin" href="" style="margin-left:30px">
          <el-button type="primary" class='gterenzhongx' :class="pathtype?'active':''" size="large">个人中心</el-button>
        </div>
        <!-- <el-link @click="enterRoadshow" href="">项目路演</el-link> -->
        <!-- <n-button v-if="isLogin" type="primary" size="large" @click="router.push('/user')">
        个人中心
      </n-button>
      <n-button v-if="isLogin" type="primary" size="large" @click="enterRoadshow">
        项目路演
      </n-button> -->
        <!-- <el-link href="http://login.sdzhrc.cn" target="_blank">管理入口</el-link> -->
        <!-- <n-button type="primary" size="large">
        <a href="http://login.sdzhrc.cn" target="_blank">管理入口</a>
      </n-button> -->
        <!-- <n-popover trigger="hover" placement="bottom">
        <template #trigger>
          <el-link href="">APP 下载</el-link>
        </template>
        <img class="w-36" src="https://qjck.oss-cn-shanghai.aliyuncs.com/handupload/appdown/zhrc.png" alt="" />
      </n-popover> -->
      </div>
    </div>
  </div>

  <!--  登录二维码 开始-->
  <n-modal v-model:show="showModal" :mask-closable="false" :on-after-leave="onModalAfterLeave">
    <n-card style="width: 700px;overflow: hidden;" title=" " :bordered="false" size="medium">
      <template #header-extra>
        <n-icon size="26" @click="closeQrcode" class="cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
            <path d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4l6.6 6.6L8 22.6L9.4 24l6.6-6.6l6.6 6.6l1.4-1.4l-6.6-6.6L24 9.4z"
              fill="currentColor"></path>
          </svg>
        </n-icon>
      </template>
      <div class="flex flex_ambo" style="height:290px;  ">
        <div style="padding-left:68px;margin-top:-80px;">
          <img class="erweima_logo_img"
            src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/erweima/48f33d78199376970b337ea3a4af6d5.png" alt="">
          <div class="erweima_content_text" style="padding-left:18px;">
            <div>
              请使用智慧人才APP
            </div>
            <div>或者小程序进行扫码</div>
          </div>
        </div>
        <div class="qrcode_o_bg">
          <img src="https://qjck.oss-cn-shanghai.aliyuncs.com/zhrc/erweima/c0661662d848723cadca02033467482.png" alt="">
        </div>
        <div class="qrcode-img" style="margin-bottom:60px;">
          <div id="login-qrcode"></div>
          <div class="qrcode-error" v-if="qrcodeBeoverdue">
            <p>{{ qrcodeMsg }}</p>
            <!-- <button class="refresh" @click="refreshQrcode">刷新二维码</button> -->
          </div>
        </div>
      </div>

    </n-card>
  </n-modal>
  <!--  登录二维码 结束-->

  <n-modal v-model:show="showRegisterModal" :mask-closable="false" :bordered="false" preset="card" title="注册"
    style="width: 500px">
    <Register @close="showRegisterModal = false" />
  </n-modal>
  <n-modal v-model:show="showRegisterModal2" :mask-closable="false" :bordered="false" preset="card" title="专家登录"
    style="width: 500px">
    <n-form :model="formModal" ref="formRef" :rules="loginRules" label-width="80px" require-mark-placement="left"
      label-placement="left" size="large" class="mt-1">
      <n-form-item label="用户名" path="account" ref="phoneRef">
        <n-input placeholder="请输入用户名" v-model:value="formModal.account" />
      </n-form-item>
      <n-form-item first path="password" label="密码">
        <n-input v-model:value="formModal.password" type="password" />
      </n-form-item>
      <n-form-item label="机构" path="tenantId">
        <n-select v-model:value="formModal.tenantId" :options="options" @click="getTenantByUserNames"
          @update:value="TenantByUserChange" :render-label="renderLabel" />

      </n-form-item>
      <n-form-item label="角色" v-if="roleList.length>1">
        <n-select v-model:value="formModal.roleId" :options="roleList" :render-label="renderLabel2">

        </n-select>
      </n-form-item>
      <div class="flex justify-end">
        <n-button :loading="saveLoading" class="px-10" type="primary" @click="submit">
          登录
        </n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script setup>
import { getUserInfoApi, lunresult, getTenantByUserName, loginApi, appGetExpert, saveLog, getStuCredit } from '@/api';
import {
  ref,
  nextTick,
  onMounted,
  onBeforeUnmount,
  computed,
  reactive,
  defineEmit,
  useContext,
  watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Register from './Regiser';
const router = useRouter();
import { useStore } from 'vuex';
import { mapGetters } from 'vuex';
import { useMessage } from 'naive-ui';
import QRCode from 'qrcodejs2';
import { uuid } from '@/utils';
const message = useMessage();
const store = useStore();
console.log(store, '------')
const imgBaseUrl = 'https://qjck.oss-cn-shanghai.aliyuncs.com/';
const formModel_deng = reactive({
  stuName: '',
  photo: '',
});
const route = useRoute();
const pathtype = ref('')
watch(() => route.query, (val, preVal) => {
  if (route.path == '/user') {
    pathtype.value = 1
  } else {
    pathtype.value = 0

  }
})
if (route.path == '/user') {
  pathtype.value = 1
} else {
  pathtype.value = 0

}
const sousuotext = ref('')
function sousuo () {
  if (sousuotext.value) {
    router.replace('/search/' + sousuotext.value)
  } else {

  }
}
const options = ref([])
const formModal = ref({
  account: '',
  password: '',
  tenantId: '',
  roleId: '',
});
const loginRules = {
  account: {
    required: true,
    message: "请输入用户名",
    trigger: "blur"
  },
  password: {
    required: true,
    message: "请输入密码",
    trigger: "blur"
  },
  tenantId: {
    required: true,
    message: "请输选择机构",
    trigger: "blur"
  },
}
function updacredit (value) {
  if (value) {
    return parseInt(value / 60 / 45)

  } else {
    return 0
  }
}
function updatapercentage (value, value2) {
  if (value && value2) {
    let num = Math.ceil((value * 100 / value2))
    if (num > 100) {
      num = 100
    }
    return num

  } else {
    return 0
  }
}
const formModalRules = {}
const isLogin = computed(() => Boolean(store.state.token));
const showRegisterModal = ref(false);
const showRegisterModal2 = ref(false)
const credit = ref({})
if (isLogin.value) {
  getStuCredit().then(res => {
    console.log(res, 'getStuCredit')
    credit.value = res.data
  })
}
function submit () {
  login()

  // formModal.value.validate(async errors => {
  //   if (errors) {
  //     return;
  //   }
  //   login()
  // });
}
// 定时器
var timer = ref(null);

// 搜索内容
const searchContent = ref({
  content: '',
});
function getTenantByUserNames () {

  if (formModal.value.account && formModal.value.password) {
    getTenantByUserName(formModal.value.account, formModal.value.password).then(res => {
      console.log(res)
      if (res.success) {
        options.value = res.data;
      } else {
        message.error(res.msg);
      }
    })
  } else {
    message.error('请输入用户名和密码后再选择机构');
  }



}
const showModal = ref(false);
const qrcodeBeoverdue = ref(false);
let qrcodeMsg = ref('');

async function handleLogin () {
  showModal.value = true;
  await nextTick();
  renderQrcode();
}
window.$login = handleLogin

const wode = '234';

function refreshQrcode () {
  document.querySelector('#login-qrcode').innerHTML = '';
  source.close()
  source = null;
  renderQrcode()
}

// 登录二维码
function renderQrcode () {
  qrcodeBeoverdue.value = false;
  const _uuid = uuid(16, 16) + new Date().getTime();
  const text = {
    uuid: _uuid,
    type: 'loginweb',
  };
  new QRCode('login-qrcode', {
    width: 164,
    height: 164,
    text: JSON.stringify(text),
  });
  connectService(_uuid);
}

// 关闭 二维码弹框
function closeQrcode () {
  showModal.value = false;
  document.querySelector('#login-qrcode').innerHTML = '';
  clearInterval(timer); // 关闭循环
}

let source = null;
const roleList = ref([])
function TenantByUserChange (e) {
  console.log(e)
  options.value.forEach(item => {
    if (item.tenantId == e) {
      console.log(item)
      roleList.value = item.roles
      if (item.roles.length == 1) {
        formModal.value.roleId = item.roles[0].roleId
      } else {
        formModal.value.roleId = ''
      }
    }
  })
}
function renderLabel (option) {
  console.log(option)
  if (option.tenantName) {
    option.value = option.tenantId
    return option.label = option.tenantName
  }
}
function renderLabel2 (option) {
  console.log(option)
  if (option.tenantName) {
    option.value = option.roleId
    return option.label = option.roleName
  }
}
function login () {
  loginApi(formModal.value).then(res => {
    console.log(res)
    if (res.code == 200 && res.data.accessToken) {
      message.success('登录成功');
      sessionStorage.setItem('TOUXIANG', '666');
      store.commit('SET_TOKEN', { token: res.data.accessToken, type: 'zj' });
      saveLog({
        deviceUuid: 'PC',
        deviceVersion: '1.0.0',
        deviceType: 4,
        app: 2
      })
      window.location.reload(true);
    } else {
      message.error(res.msg);
    }
  })
}
function connectService (uuid) {
  console.log('#########################');
  if (window.EventSource) {
    // 建立连接
    source = new EventSource('/api/blade-app/website/connect/' + uuid);

    /**
     * 连接一旦建立，就会触发open事件
     * 另一种写法：source.onopen = function (event) {}
     */
    source.onopen = function (event) {
      console.log(event);
    };

    /**
     * 客户端收到服务器发来的数据
     * 另一种写法：source.onmessage = function (event) {}
     */
    source.onmessage = function (event) {
      console.log(event);
      let res = event.data;
      if (!res) {
        return;
      }
      if (res === '扫码中') {
        qrcodeMsg.value = '扫码中';
        qrcodeBeoverdue.value = true;
        return;
      }
      try {
        res = JSON.parse(res);
        if (res.success) {
          qrcodeMsg.value = '登录成功';
          // debugger;
          saveLog({
            deviceUuid: 'PC',
            deviceVersion: '1.0.0',
            deviceType: 4,
            app: 2
          })
          console.log(res);
          // debugger;
          message.success('登录成功');
          sessionStorage.setItem('TOUXIANG', '123');
          store.commit('SET_TOKEN', res.token);
          cancelConnect();
          router.replace('/learning-center/online');
          setTimeout(() => {
            window.location.reload(true);

          }, 100);
        } else {
          message.error(res.msg);
          qrcodeMsg.value = res.msg;
          qrcodeBeoverdue.value = true;
          cancelConnect();
        }
      } catch (err) {
        message.error('扫码登录出现错误，请刷新页面后重试！');
        qrcodeMsg.value = '请刷新页面后重试';
        qrcodeBeoverdue.value = true;
        cancelConnect();
      }
    };

    /**
     * 如果发生通信错误（比如连接中断），就会触发error事件
     * 或者：
     * 另一种写法：source.onerror = function (event) {}
     */
    source.onerror = function (event) {
      message.error('扫码登录出现异常，请刷新页面后重试！');
      qrcodeMsg.value = '请刷新页面后重试';
      qrcodeBeoverdue.value = true;
      cancelConnect();
    };
  } else {
    console.log('@@@@@');

    // var sum = ref(0);
    timer = setInterval(function () {
      lunresult(uuid).then(res => {
        if (JSON.stringify(res.data) !== '{}' && res.success === true) {
          qrcodeBeoverdue.value = true;
          console.log(res);
          qrcodeMsg.value = '登录成功';
          message.success('登录成功');
          sessionStorage.setItem('TOUXIANG', '123');
          store.commit('SET_TOKEN', res.data.token);
          router.replace('/');

          cancelConnect();
          // window.location.reload(true);
          clearInterval(timer); // 关闭循环
        }
      });
    }, 2000);

    //
  }
}

function onModalAfterLeave () {
  cancelConnect();
}

function cancelConnect () {
  if (source) {
    source.close();
    source = null;
  }
}
function enterRoadshow () {
  router.push('/roadshow')

}
onMounted(() => {
  window.addEventListener('unload', cancelConnect);
});

onBeforeUnmount(() => {
  window.removeEventListener('unload', cancelConnect);
  cancelConnect();
});

const studentMessage = reactive({});
// 使用生命周期函数
onMounted(() => {
  console.log('!!!!!!!!!!!!!');
  const isLogin_deng = computed(() => Boolean(store.state.token));
  const type = computed(() => store.state.type);
  console.log(type);
  if (isLogin_deng.value) {
    if (type.value == 'zj') {
      appGetExpert().then(res => res.data)
        .then(res => {
          console.log(res);
          Object.assign(studentMessage, res);
          const { stuName, photo } = res;
          formModel_deng.stuName = stuName;
          formModel_deng.proof = photo;
        });
    } else {
      getUserInfoApi()
        .then(res => res.data)
        .then(res => {
          console.log(res);
          Object.assign(studentMessage, res);
          const { stuName, photo } = res;
          formModel_deng.stuName = stuName;
          formModel_deng.proof = photo;

        });
    }

  }
});
// const www = computed(() => store.state.search);
// watch(www, () => {
//   console.log('我监听vuex');
// });

// 搜索
function allSearch () {
  if (store.state.search !== '') {
    store.state.search = '';
  }

  console.log('搜索');
  setTimeout(() => {
    store.state.search = searchContent.value.content;
    if (searchContent.value.content === '') {
      window.location.reload(true);
    }
  }, 500);

  // console.log(store.state.search);
  //  store
  // console.log(store);
  // store.state.type = 'wode ';
  // console.log(store.state.type);
}
</script>
<style scoped>
.gterenzhongx {
  background-color: #fff;
  border: 1px solid #2d60f6;
  color: #2d60f6;
  border-radius: 40px;
  width: 120px;
  font-size: 16px;
}
.gterenzhongx:hover {
  color: #2d60f6;
  background-color: rgb(236, 245, 255);
}
.gterenzhongx.active {
  background-color: #2d60f6;
  color: #fff;
}
</style>
<style >
.el-link--inner {
  font-size: 22px;
  /* margin-right: 10px; */
}
</style>
<style lang="scss" scoped>
.nav_header_box {
  background-color: rgb(70, 74, 79);
}
.nav_header {
  width: 100%;
  height: 65px;
  // border-bottom: 1px solid #eee;
  align-items: center;
  justify-content: space-between;
}
.nav_header_title {
  font-size: 18px;
  font-family: SourceHanSansCN-Light Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  // line-height: 48px;
}
.nav_header_phone_box {
  font-size: 18px;
  font-family: SourceHanSansCN-Light Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  margin-left: 80px;
  height: 30px;
  line-height: 30px;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
}
.nav_header_phone {
  height: 18px;
  margin-right: 10px;
}
.seah {
  height: 34px;
  width: 300px;
}
.seah_btn {
  height: 34px;
}
.seah_input {
  line-height: 34px;
}
.header {
  height: 80px;
  padding-right: 10px;
  justify-content: space-between;
  background-color: #fff;
}
.logo {
  // width: 70px;
  height: 45px;
  // height: auto;
}
.qrcode-img {
  position: relative;
  box-shadow: 0px 0px 1px 1px rgba(28, 28, 32, 0.05), 0px 8px 24px rgba(28, 28, 32, 0.12);
  border-radius: 10px;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  font-size: 14px;
  background-color: #fff;
  overflow: hidden;
  padding: 8px;
}
.qrcode_o_bg {
  width: 308px;
  height: 287px;
  // background-color: red;
  z-index: 0;
  position: absolute;
  right: 0;
  opacity: 0.2;
  bottom: 0;
}

.qrcode-error {
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  > p {
    color: #222;
    margin-top: 75px;
    margin-bottom: 8px;
    text-align: center;
    font-size: 18px;
  }

  .refresh {
    font-size: 12px;
    color: #f0f0f0;
    background: #1890ff;
    line-height: 24px;
    height: 24px;
    width: auto;
    padding: 0px 10px;
    border-radius: 30px;
    border: 0;
    margin: 0 auto;
    display: block;
  }
}

.qrcode-desc {
  text-align: center;
  font-size: 12px;
  color: #25262b;
}

.personage_1 {
  // display: inline-block;
  width: 48px;
  height: 48px;
  // height: 22px;
  img {
    border-radius: 25px;
  }
}
.personage_2 {
  // display: inline-block;
  line-height: 80px;
  height: 80px;
  font-size: 18px;
  margin-right: 20px;
}
.serch_input {
  width: 178px;
  height: 27px !important;
  box-sizing: border-box;
  margin-right: 40px;
}
.ambo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
}
.hovereff {
  cursor: pointer;
}
.hovereff:hover {
  color: #127eeb;
}
.marginoo {
  display: inline-block;
  margin: 0 10px !important;
}
.serch_input_box {
  // text-align: center;
  width: 490px;
  height: 45px !important;
  border-radius: 45px;
  overflow: hidden;
  // background-color: red;
  // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #afb6c7;
}
.serch_input_box_b {
  // box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 1);
  // background-color: #e2e7eb;
  padding: 10px 0;
  margin-right: 45px;
  position: relative;
}
.serch_input_box_btn {
  color: #fff;
  height: 39px;
  position: absolute;
  right: 3px;
  border-radius: 25px;
  text-align: center;
  line-height: 39px;
  width: 140px;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
  top: 13px;
  cursor: pointer;
  background-image: linear-gradient(to right, #235bf3, #5cacd2, #82e782);
}
.serch_input_box_btn:hover {
  opacity: 0.8;
}
</style>
<style >
.serch_input .el-input__inner {
  box-sizing: border-box;
  position: absolute;
  border: none !important;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 18px !important;
  box-sizing: border-box;
  padding-right: 140px;
}
.serch_input .el-input__inner::placeholder {
  color: #b8c7e5 !important;
}
.serch_input_box_in {
  width: 100% !important;
  height: 100%;
}
.serch_input {
  margin-right: 0 !important;
}
.serch_input .el-input__suffix {
  display: flex;
  align-items: center;
}
</style>
