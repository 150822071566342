import { createRouter, createWebHashHistory } from 'vue-router';
import { getToken, getCydsToken } from '@/utils/auth';
import Home from '@/views/home/Home.vue';

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/page-init/Register'),
  },
  {
    path: '/dictation',
    name: 'dictation',
    component: () => import('@/views/dictation/index'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/index'),
  },
  {
    path: '/search/:title',
    name: 'search',
    component: () => import('@/views/news/search'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/learning-center/online',
    name: 'Online',
    component: () => import('@/views/learning-center/Online'),

  },
  {
    path: '/learning-center/online-detail/:id(\\d+)',
    name: 'OnlineDetail',
    component: () => import('@/views/learning-center/OnlineDetail'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/learning-center/offline',
    name: 'Offline',
    component: () => import('@/views/learning-center/Offline'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/learning-center/textbook-collection',
    name: 'TextbookCollection',
    component: () => import('@/views/learning-center/TextbookCollection'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/learning-center/exams',
    name: 'Exams',
    component: () => import('@/views/learning-center/Exams'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/learning-center/review',
    name: 'Review',
    component: () => import('@/views/learning-center/Review'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/learning-center/cer-completion',
    name: 'CerCompletion',
    component: () => import('@/views/learning-center/CerCompletion'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/learning-center/agreement',
    name: 'Agreement',
    component: () => import('@/views/learning-center/Agreement'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/train',
    name: 'Train',
    component: () => import('@/views/train/Train'),
  },
  {
    path: '/platform',
    name: 'Platform',
    component: () => import('@/views/platform/Platform'),
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import('@/views/management/Management'),
  },
  {
    path: '/appraisal',
    name: 'Appraisal',
    component: () => import('@/views/appraisal/Appraisal'),
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/service/Service'),
  },
  {
    path: '/control',
    name: 'Control',
    component: () => import('@/views/control/Control'),
  },
  {
    path: '/data-analysis',
    name: 'DataAnalysis',
    component: () => import('@/views/data-analysis/DataAnalysis'),
  },
  {
    path: '/archives-center',
    name: 'ArchivesCenter',
    component: () => import('@/views/archives-center/ArchivesCenter'),
  },
  {
    path: '/ceshi',
    name: 'Ceshi',
    component: () => import('@/views/Ceshi'),
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/User'),
    meta: {
      isLogin: true,
    },
  }, {
    path: '/roadshow',
    name: 'Roadshow',
    component: () => import('@/views/roadshow/Roadshow'),
    meta: {
      isLogin: true,
    },
  }, {
    path: '/cyds',
    name: 'Cyds',
    component: () => import('@/views/cyds/index'),
    meta: {
    },
    children: [
      {
        path: '/cyds',
        component: () => import('@/views/cyds/Home')
      },
      {
        path: '/cyds/home',
        component: () => import('@/views/cyds/Home')
      },
      {
        path: '/cyds/index',
        component: () => import('@/views/cyds/index')
      },
      {
        path: '/cyds/signup',
        component: () => import('@/views/cyds/signup'),
        meta: {
          iscydsLogin: true,

        },
      }
    ]
  }, {
    path: '/cyds/login',
    name: 'CydsLogin',
    component: () => import('@/views/cyds/login'),
    meta: {
      iscydsLogin: true,
    },
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: () => ({ top: 0 }),
});

router.beforeEach((to, from, next) => {
  console.log(1)
  if (getToken()) {
    if (to.meta.iscydsLogin && !getCydsToken()) {
      window.message.warning('请您未登录，请先登录');
      next('/cyds/home');
      return
    }
    next();
  } else {
    if (to.path.indexOf('learning-center/online-detail') && to.query.type == 1) {
      next();
      return
    }
    console.log(to)
    if (to.meta.isLogin) {
      // window.message.warning('请您未登录，请先登录');
      // next('/');
      window.$login()
    } else {
      if (to.meta.iscydsLogin && !getCydsToken()) {
        window.message.warning('请您未登录，请先登录');
        next('/cyds/home');
      } else {
        next();
      }
    }
  }
});

export default router;
