export function getBrowserInfo () {
  const Sys = {};
  const ua = navigator.userAgent.toLowerCase();
  const re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/;
  const m = ua.match(re);
  try {
    Sys.browser = m[1].replace(/version/, "'safari");
    Sys.ver = m[2];
  } catch (e) {
    console.log('getBrowserInfo fail.');
  }
  return Sys;
}

export function uuid (len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}

export function mapOption (data, option = { label: 'label', value: 'value' }) {
  const keys = Object.keys(option);
  return data.map(v => {
    const obj = {};
    keys.forEach(key => {
      obj[option[key]] = v[key];
    });
    return { ...v, ...obj };
  });
}
export function urlConversion (path) {
  let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
  path = path.replace(reg, "https://$2$3$4$5$6");
  return path
}
/**
 * 日期格式化
 */
function dateFormat (date, format = 'yyyy-MM-dd hh:mm:ss') {
  if (date !== 'Invalid Date') {
    const o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      S: date.getMilliseconds(), // millisecond
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
        );
      }
    }
    return format;
  }
  return '';
}
export function setInitImg (url, callback) {
  let img = url;//这里是淘宝上随便找的一张图片
  let _ = this
  let imgRes
  getBase64(img, (dataURL) => {
    imgRes = dataURLtoFile(dataURL, img);
    console.log(imgRes)
    callback(imgRes)
  });
}

function getBase64 (url, callback) {
  //通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 <img> 省去了 append()，也就避免了文档冗余和污染
  var Img = new Image(),
    dataURL = "";
  Img.setAttribute("crossOrigin", "anonymous"); // 解决控制台跨域报错的问题

  Img.onload = function () {
    //要先确保图片完整获取到，这是个异步事件
    var canvas = document.createElement("canvas"), //创建canvas元素
      width = Img.width, //确保canvas的尺寸和图片一样
      height = Img.height;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
    dataURL = canvas.toDataURL("image/jpeg"); //转换图片为dataURL
    callback ? callback(dataURL) : null; //调用回调函数
  };
  Img.src = url + '&n=' + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;

}

function dataURLtoFile (dataurl, filename) {
  //将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}


